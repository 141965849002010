import React from 'react';
import DataTable, { Alignment, Direction } from "react-data-table-component";
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg'
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg'
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg'

const LinkReminders = () => {
    const columns = [
        {
            name: "REminder Title",
            selector: (row) => row.reminderTitle,
            sortable: false,
            reorder: true,
        },
        {
            name: "Frequency",
            selector: (row) => row.frequency,
            sortable: false,
            reorder: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: false,
            reorder: true,
        },

    ];
    const data = [
        {
            id: 1,
            reminderTitle: <div className='REminderTitle'> Cleaning</div>,
            frequency: <div className='Frequency'> Fortnightly</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 2,
            reminderTitle: <div className='REminderTitle'> Service</div>,
            frequency: <div className='Frequency'> Once a month</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 3,
            reminderTitle: <div className='REminderTitle'> Regular Check</div>,
            frequency: <div className='Frequency'> Every 2 weeks</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 4,
            reminderTitle: <div className='REminderTitle'> Deep Cleaning</div>,
            frequency: <div className='Frequency'> Everyday</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 5,
            reminderTitle: <div className='REminderTitle'> Other</div>,
            frequency: <div className='Frequency'> Every Monday</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={deleteIcon} /></span></div>

        },

    ]



    return (
        <div className="linkReminderTable">
            <DataTable
                columns={columns}
                data={data}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={false}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={"300px"}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />

        </div>

    );
}
export default LinkReminders;