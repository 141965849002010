import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import RegistrationHomeowner from '../components/auth/inc/registration_homeowner';
import RegistrationAffliate from '../components/auth/inc/registration_affliate';
import RegistrationHomeownerAffliate from '../components/auth/inc/registration_homeowner_affliate';

const Registration = () => {
  const [showPass, setShowPass] = useState(false);

  const [roleSwticher, setRoleSwticher] = useState(null);
  const [roleSwtich, setRoleSwtich] = useState(null);


  const handlerRoleSwticher = () => {
    setRoleSwtich(roleSwticher)
  }

  const handlerShowPassword = () => {
    if (showPass) {
      setShowPass(false);

    } else {
      setShowPass(true);

    }
  }
  return (


    <div className="auth-form-light text-left py5 px-4 ">
      {roleSwtich == null ?


        <>

          <div className="brand-logo">
            <h2 className='auth-title'>Registration</h2>
          </div>

          <div className='role-selection'>
            <div className='top-text-rol'>
              <span>Please select your role</span>
            </div>



            <label className={roleSwticher === 'homeowner' ? 'select-role-section-active select-role-section' : 'select-role-section'} htmlFor="Homeowner">

              <div className='text-role-selection' >
                Homeowner
              </div>
              <div className='radio-role-selection '>
                <input type='radio' id="Homeowner" value='homeowner' onChange={e => setRoleSwticher(e.target.value)} className='role-radio-btn' name='role_choose' />
              </div>
            </label>

            <label className={roleSwticher === 'affiliate' ? 'select-role-section-active select-role-section' : 'select-role-section'} htmlFor="Affiliate">

              <div className='text-role-selection' >
                Affiliate
              </div>
              <div className='radio-role-selection'>
                <input type='radio' id="Affiliate" value='affiliate' onChange={e => setRoleSwticher(e.target.value)} className='role-radio-btn' name='role_choose' />
              </div>
            </label>

            <label className={roleSwticher === 'homeownerAffiliate' ? 'select-role-section-active select-role-section' : 'select-role-section'} htmlFor="AffiliateHomeowner">

              <div className='text-role-selection' >
                Homeowner + Affiliate
              </div>
              <div className='radio-role-selection'>
                <input type='radio' id="AffiliateHomeowner" onChange={e => setRoleSwticher(e.target.value)} value='homeownerAffiliate' className='role-radio-btn' name='role_choose' />
              </div>
            </label>
            <div className="auth-footer">

              <button type='button' className='auth-success-btn' onClick={handlerRoleSwticher} name='nextbutton'   >Next</button>

            </div>
            <div className="auth-footer">
              <Link className="auth-link" to="/">I have an account</Link>
            </div>
          </div>




        </>
        : roleSwtich == 'homeowner' ? <RegistrationHomeowner />

          : roleSwtich == 'affiliate' ? <RegistrationAffliate />
            : roleSwtich == 'homeownerAffiliate' ? <RegistrationHomeownerAffliate />
              : ''

      }
    </div>

  );
}

export default Registration;
