import React from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import plusIcon from '../../asstes/dash-icons/plusIcon.svg'

import MagnifyingGlass from '../../asstes/dash-icons/sidebar/MagnifyingGlass.svg'


const FilesSideNavAdd = ({handlerCustomReminder}) => {

    const suggestedReminders = [
        {
            id: 1,
            title: 'Vulputate hac augue tempor facilisi dolor.'
        },
        {
            id: 2,
            title: 'Integer consectetur tellus ac sed sagittis aenean'
        },
        {
            id: 3,
            title: 'Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac.'
        },
        {
            id: 4,
            title: 'Convallis massa porta non diam phasellus turpis nibh velit.'
        },
        {
            id: 5,
            title: 'Eget quis viverra ultrices a suscipit duis nulla.'
        },
        {
            id: 4,
            title: 'Convallis massa porta non diam phasellus turpis nibh velit.'
        },
        {
            id: 5,
            title: 'Eget quis viverra ultrices a suscipit duis nulla.'
        },
        {
            id: 4,
            title: 'Convallis massa porta non diam phasellus turpis nibh velit.'
        },
        {
            id: 5,
            title: 'Eget quis viverra ultrices a suscipit duis nulla.'
        },

    ];

    const customeReminders = [
        {
            id: 1,
            title: 'Molestie faucibus diam sed pharetra tristique '
        }
    ];

    const handlerCustomReminderNEW = ()=>{
        handlerCustomReminder(true);
    }

    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>Add File</h3>
                <h5></h5>

            </div>
            <div className='side-body'>
                <Form>
                    <Row className="mb-3">

                        <Form.Group as={Col} md="6" className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>File Name</Form.Label>
                            <Form.Control type="text" placeholder="File name" />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Upload Date </Form.Label>
                            <Form.Control type="date" placeholder="Name" />

                        </Form.Group>
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen uploadFile'>
                                <div className='title'>You can attach diff formats png, pdf, jpg, doc, docx.</div>

                                <div className='button'>
                                    <div className="outline-button-upload">

                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 7.50015V11.0001C12 11.2654 11.8946 11.5197 11.7071 11.7073C11.5196 11.8948 11.2652 12.0001 11 12.0001H1C0.734784 12.0001 0.48043 11.8948 0.292893 11.7073C0.105357 11.5197 0 11.2654 0 11.0001V7.50015C0 7.36754 0.0526785 7.24036 0.146447 7.1466C0.240215 7.05283 0.367392 7.00015 0.5 7.00015C0.632608 7.00015 0.759785 7.05283 0.853553 7.1466C0.947321 7.24036 1 7.36754 1 7.50015V11.0001H11V7.50015C11 7.36754 11.0527 7.24036 11.1464 7.1466C11.2402 7.05283 11.3674 7.00015 11.5 7.00015C11.6326 7.00015 11.7598 7.05283 11.8536 7.1466C11.9473 7.24036 12 7.36754 12 7.50015ZM3.85375 3.3539L5.5 1.70702V7.50015C5.5 7.63276 5.55268 7.75993 5.64645 7.8537C5.74021 7.94747 5.86739 8.00015 6 8.00015C6.13261 8.00015 6.25979 7.94747 6.35355 7.8537C6.44732 7.75993 6.5 7.63276 6.5 7.50015V1.70702L8.14625 3.3539C8.24007 3.44772 8.36732 3.50043 8.5 3.50043C8.63268 3.50043 8.75993 3.44772 8.85375 3.3539C8.94757 3.26008 9.00028 3.13283 9.00028 3.00015C9.00028 2.86747 8.94757 2.74022 8.85375 2.6464L6.35375 0.146399C6.30731 0.0999109 6.25217 0.0630315 6.19147 0.0378693C6.13077 0.0127072 6.06571 -0.000244141 6 -0.000244141C5.93429 -0.000244141 5.86923 0.0127072 5.80853 0.0378693C5.74783 0.0630315 5.69269 0.0999109 5.64625 0.146399L3.14625 2.6464C3.05243 2.74022 2.99972 2.86747 2.99972 3.00015C2.99972 3.13283 3.05243 3.26008 3.14625 3.3539C3.24007 3.44772 3.36732 3.50043 3.5 3.50043C3.63268 3.50043 3.75993 3.44772 3.85375 3.3539Z" fill="#00487C" />
                                        </svg>

                                        Upload file
                                        <input type='file' className='chooeseFile' />
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </Row>

                    <div className='create-reminder-parent'>
                        <InputGroup className='search-field '>
                            <InputGroup.Text id="basic-addon1"><img src={MagnifyingGlass} /></InputGroup.Text>
                            <Form.Control type='text' placeholder='Search Reminder' />
                        </InputGroup>
                        <div className='button uploadFile' onClick={handlerCustomReminderNEW}>
                            <div className="outline-button-upload createReminders">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 7.50015V11.0001C12 11.2654 11.8946 11.5197 11.7071 11.7073C11.5196 11.8948 11.2652 12.0001 11 12.0001H1C0.734784 12.0001 0.48043 11.8948 0.292893 11.7073C0.105357 11.5197 0 11.2654 0 11.0001V7.50015C0 7.36754 0.0526785 7.24036 0.146447 7.1466C0.240215 7.05283 0.367392 7.00015 0.5 7.00015C0.632608 7.00015 0.759785 7.05283 0.853553 7.1466C0.947321 7.24036 1 7.36754 1 7.50015V11.0001H11V7.50015C11 7.36754 11.0527 7.24036 11.1464 7.1466C11.2402 7.05283 11.3674 7.00015 11.5 7.00015C11.6326 7.00015 11.7598 7.05283 11.8536 7.1466C11.9473 7.24036 12 7.36754 12 7.50015ZM3.85375 3.3539L5.5 1.70702V7.50015C5.5 7.63276 5.55268 7.75993 5.64645 7.8537C5.74021 7.94747 5.86739 8.00015 6 8.00015C6.13261 8.00015 6.25979 7.94747 6.35355 7.8537C6.44732 7.75993 6.5 7.63276 6.5 7.50015V1.70702L8.14625 3.3539C8.24007 3.44772 8.36732 3.50043 8.5 3.50043C8.63268 3.50043 8.75993 3.44772 8.85375 3.3539C8.94757 3.26008 9.00028 3.13283 9.00028 3.00015C9.00028 2.86747 8.94757 2.74022 8.85375 2.6464L6.35375 0.146399C6.30731 0.0999109 6.25217 0.0630315 6.19147 0.0378693C6.13077 0.0127072 6.06571 -0.000244141 6 -0.000244141C5.93429 -0.000244141 5.86923 0.0127072 5.80853 0.0378693C5.74783 0.0630315 5.69269 0.0999109 5.64625 0.146399L3.14625 2.6464C3.05243 2.74022 2.99972 2.86747 2.99972 3.00015C2.99972 3.13283 3.05243 3.26008 3.14625 3.3539C3.24007 3.44772 3.36732 3.50043 3.5 3.50043C3.63268 3.50043 3.75993 3.44772 3.85375 3.3539Z" fill="#00487C" />
                                </svg>
                                Custom reminder
                            </div>
                        </div>
                    </div>

                    <div className='mb-3'>
                        <h1 className='side-nav-main-heading'>Custom reminders</h1>

                        {customeReminders.map((item) => {
                            return (
                                <div className='suggetes-item' key={item.id}>
                                    <div className='titleSuggestItem'>{item.title}</div>
                                    <div><img src={plusIcon} /></div>


                                </div>


                            )
                        })}
                        <h1 className='side-nav-main-heading'>Suggested reminders</h1>
                    </div>
                    {suggestedReminders.map((item) => {
                        return (
                            <div className='suggetes-item' key={item.id}>
                                <div className='titleSuggestItem'>{item.title}</div>
                                <div><img src={plusIcon} /></div>


                            </div>


                        )
                    })}

                </Form>


            </div>
        </div>
    );
}

export default FilesSideNavAdd;
