import React from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg'
import copyIcon from '../assets/icons/copyIcon.svg'
import deActiveIcon from '../assets/icons/deActiveIcon.svg'
import ActiveIcon from '../assets/icons/ActiveIcon.svg'
import DataTable, { Alignment, Direction } from 'react-data-table-component';


const ShowCodeHistory = () => {

    const columns = [
        {
            name: "Referral Code",
            selector: (row) => row.ClientName,
            sortable: false,
            reorder: true,
        },
        {
            name: "Status",
            selector: (row) => row.ReferralCode,
            sortable: false,
            reorder: true,
        },
        {
            name: "Date Created",
            selector: (row) => row.RenewalDate,
            sortable: false,
            reorder: true,
        },

        {
            name: "Action",
            selector: (row) => row.Action,
            sortable: false,
            reorder: true,
        }


    ];
    const data = [
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName ReferralStatus type-green'>Active</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,

            Action: <div className='actions'><span><img src={ActiveIcon} /></span><span><img src={copyIcon} /></span></div>


        },
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName ReferralStatus type-green'>Active</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,

            Action: <div className='actions'><span><img src={ActiveIcon} /></span><span><img src={copyIcon} /></span></div>


        },
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName ReferralStatus type-blue'>In-Active</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,

            Action: <div className='actions'><span><img src={deActiveIcon} /></span><span><img src={copyIcon} /></span></div>


        },
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName ReferralStatus type-green'>Active</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,

            Action: <div className='actions'><span><img src={ActiveIcon} /></span><span><img src={copyIcon} /></span></div>


        },
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName ReferralStatus type-green'>Active</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,

            Action: <div className='actions'><span><img src={ActiveIcon} /></span><span><img src={copyIcon} /></span></div>


        },
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName ReferralStatus type-blue'>in-Active</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,

            Action: <div className='actions'><span><img src={deActiveIcon} /></span><span><img src={copyIcon} /></span></div>


        },



    ];

    return (
        <div className="recentFilesTable recentEarning">
            <DataTable
                columns={columns}
                data={data}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={true}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={"300px"}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />

        </div>
    );
}

export default ShowCodeHistory;
