import React from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg'
import editIcon from '../assets/icons/edit-icon.svg'
import BellSimple from '../assets/icons/BellSimple.svg'
import deleteIcon from '../../user-dashboard/asstes/dash-icons/deleteIcon.svg'
import DataTable, { Alignment, Direction } from 'react-data-table-component';

const MyNetworkTable = () => {
    const columns = [
        {
            name: "First Name",
            selector: (row) => row.FirstName,
            sortable: true,
            reorder: true,
        },
        {
            name: "Last Name",
            selector: (row) => row.LastName,
            sortable: true,
            reorder: true,
        },
        {
            name: "Sign Up Date",
            selector: (row) => row.UserCreationDate,
            sortable: true,
            reorder: true,
        },
        {
            name: "Renewal Date",
            selector: (row) => row.SubscriptionRenewal,
            sortable: true,
            reorder: true,
        },
        // {
        //     name: "Service Renewal Reminder",
        //     selector: (row) => row.REmindUsers,
        //     sortable: true,
        //     reorder: true,
        // }


    ];
    const data = [
        {
            id: 1,
            FirstName: <div className='fileName'>Thoreau</div>,
            LastName: <div className='fileName'>Jordane</div>,
            UserCreationDate: <div className='fileName'>04/01/2023</div>,
            SubscriptionRenewal: <div className='fileName'>04/01/2023</div>,
            REmindUsers: <div className='fileName bellIcon'><img src={BellSimple} />REmind</div>,
 
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            FirstName: <div className='fileName'>Thoreau</div>,
            LastName: <div className='fileName'>Jordane</div>,
            UserCreationDate: <div className='fileName'>04/01/2023</div>,
            SubscriptionRenewal: <div className='fileName'>04/01/2023</div>,
            REmindUsers: <div className='fileName bellIcon'><img src={BellSimple} />REmind</div>,
 
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            FirstName: <div className='fileName'>Thoreau</div>,
            LastName: <div className='fileName'>Jordane</div>,
            UserCreationDate: <div className='fileName'>04/01/2023</div>,
            SubscriptionRenewal: <div className='fileName'>04/01/2023</div>,
            REmindUsers: <div className='fileName bellIcon'><img src={BellSimple} />REmind</div>,
 
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            FirstName: <div className='fileName'>Thoreau</div>,
            LastName: <div className='fileName'>Jordane</div>,
            UserCreationDate: <div className='fileName'>04/01/2023</div>,
            SubscriptionRenewal: <div className='fileName'>04/01/2023</div>,
            REmindUsers: <div className='fileName bellIcon'><img src={BellSimple} />REmind</div>,
 
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            FirstName: <div className='fileName'>Thoreau</div>,
            LastName: <div className='fileName'>Jordane</div>,
            UserCreationDate: <div className='fileName'>04/01/2023</div>,
            SubscriptionRenewal: <div className='fileName'>04/01/2023</div>,
            REmindUsers: <div className='fileName bellIcon'><img src={BellSimple} />REmind</div>,
 
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            FirstName: <div className='fileName'>Thoreau</div>,
            LastName: <div className='fileName'>Jordane</div>,
            UserCreationDate: <div className='fileName'>04/01/2023</div>,
            SubscriptionRenewal: <div className='fileName'>04/01/2023</div>,
            REmindUsers: <div className='fileName bellIcon'><img src={BellSimple} />REmind</div>,
 
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
       

    ]

    return (
        <div className="recentFilesTable recentEarning">
            <DataTable
                columns={columns}
                data={data}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={true}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={"300px"}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />

        </div>
    );
}

export default MyNetworkTable;
