import React from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg'
import editIcon from '../assets/icons/edit-icon.svg'
import deleteIcon from '../../user-dashboard/asstes/dash-icons/deleteIcon.svg'
import DataTable, { Alignment, Direction } from 'react-data-table-component';

const RecentEarning = () => {
    const columns = [
        {
            name: "Source",
            selector: (row) => row.Source,
            sortable: true,
            reorder: true,
        },
        {
            name: "Client Name",
            selector: (row) => row.ClientName,
            sortable: true,
            reorder: true,
        },
        {
            name: "Date",
            selector: (row) => row.Date,
            sortable: true,
            reorder: true,
        },
        {
            name: "Amount",
            selector: (row) => row.Amount,
            sortable: true,
            reorder: true,
        },
        // {
        //     name: "Action",
        //     selector: (row) => row.ActionNot,
        //     sortable: true,
        //     reorder: true,
        // }


    ];
    const data = [
        {
            id: 1,
            Source: <div className='fileName'>Referral Bonus</div>,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            Date: <div className='fileName'>04/01/2023</div>,
            Amount: <div className='fileName'>$ 3.2</div>,

            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            Source: <div className='fileName'>Referral Bonus</div>,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            Date: <div className='fileName'>04/01/2023</div>,
            Amount: <div className='fileName'>$ 3.2</div>,

            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            Source: <div className='fileName'>Referral Bonus</div>,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            Date: <div className='fileName'>04/01/2023</div>,
            Amount: <div className='fileName'>$ 3.2</div>,

            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            Source: <div className='fileName'>Referral Bonus</div>,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            Date: <div className='fileName'>04/01/2023</div>,
            Amount: <div className='fileName'>$ 3.2</div>,

            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            Source: <div className='fileName'>Referral Bonus</div>,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            Date: <div className='fileName'>04/01/2023</div>,
            Amount: <div className='fileName'>$ 3.2</div>,

            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            Source: <div className='fileName'>Referral Bonus</div>,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            Date: <div className='fileName'>04/01/2023</div>,
            Amount: <div className='fileName'>$ 3.2</div>,

            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            Source: <div className='fileName'>Referral Bonus</div>,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            Date: <div className='fileName'>04/01/2023</div>,
            Amount: <div className='fileName'>$ 3.2</div>,

            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id: 1,
            Source: <div className='fileName'>Referral Bonus</div>,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            Date: <div className='fileName'>04/01/2023</div>,
            Amount: <div className='fileName'>$ 3.2</div>,

            Action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={editIcon} /></span><span><img src={deleteIcon} /></span></div>

        },

    ]

    return (
        <div className="recentFilesTable recentEarning">
            <DataTable
                columns={columns}
                data={data}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={true}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={"300px"}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />

        </div>
    );
}

export default RecentEarning;
