import React from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListOfFIlesForEssemtialNav from './ListOfFIlesForEssemtialNav';
import placeholderImage from '../../asstes/dash-icons/placeholderImage.svg'
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg'
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg'
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg'
import LinkReminders from './linkReminders';


const HomeEssentialsSideNavView = (props) => {
    const { propsValue } = props;

    const images = [
        {
            id: 1,
            imageUrl: placeholderImage
        },
        {
            id: 2,
            imageUrl: placeholderImage
        },
        {
            id: 3,
            imageUrl: placeholderImage
        },
        {
            id: 4,
            imageUrl: placeholderImage
        },
        {
            id: 5,
            imageUrl: placeholderImage
        },
        {
            id: 6,
            imageUrl: placeholderImage
        },
        {
            id: 7,
            imageUrl: placeholderImage
        },
        {
            id: 8,
            imageUrl: placeholderImage
        },
    ]
    return ( 
        <div className='side-nav'>
            <div className='side-header'>
                <h3>{propsValue[0].title}</h3>
                <h5>Goodman G350</h5>
                <h5>SN: 4689-65498-4646</h5>

            </div>
            <div className='side-body homeEssentialSideNav'>
                <Form>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Notes</Form.Label>
                                <p className='text-paragraph'>Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla. Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla.</p>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>
                                <Form.Label>Files</Form.Label>

                                <ListOfFIlesForEssemtialNav />

                            </div>

                        </Form.Group>


                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Images</Form.Label>
                                <div className='listOfImages'>
                                    {images.map((img) => {
                                        return (
                                            <div className='' key={img.id}><img src={img.imageUrl} alt='image' /></div>

                                        )
                                    })}
                                </div>

                            </div>

                        </Form.Group>


                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Project</Form.Label>
                                <p className='text-paragraph'> Project Name here</p>

                            </div>

                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Linked Services</Form.Label>
                                <div className='listOfImages'>
                                <span class="services-badge  type-blue">Home Essential</span>
                                <span class="services-badge  type-green">Home Essential</span>
                                <span class="services-badge  type-camel">Home Essential</span>
                                <span class="services-badge  type-red">Home Essential</span>
                                </div>

                            </div>

                        </Form.Group>

                        <Form.Group md="12"  className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Linked Reminders</Form.Label>
                                <LinkReminders />
                            </div>



                        </Form.Group>
                        <Form.Group md="12"  className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Warranty</Form.Label>
                                <div className='warranty-section'>
                                    <span><b>Name: </b>Name of Warranty </span>
                                    <span><b>Number: </b>123456789 </span>
                                    <span><b>Dates: </b>02.01.2023 - 02.01.2028</span>
                                    <span><b>Notes: </b></span>
                                    <span><p>Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla. Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla.</p> </span>
                                    <span><b>File: </b></span>
                                    <span>
                                        <div className='warrantyFile'>
                                        <div className='text'><img src={pdfIcon} alt='pdfIcon' />document_name.pdf</div>
                                        <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span></div>
                                        </div>
                                        
                                        </span>


                                </div>
                                
                            </div>



                        </Form.Group>







                    </Row>

                </Form>
            </div>




        </div>
    );
}

export default HomeEssentialsSideNavView;
