import React from 'react';
import { Link } from 'react-router-dom';
import swtichIcon from '../assets/icons/refresh-cw.svg';
import walletIcon from '../assets/icons/walletIcon.svg';
import stripeIcon from '../assets/icons/stripeIcon.svg';
import walletIconWhite from '../assets/icons/walletIconWhite.svg';


const SellerPayments = () => {
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">

                        <div className="">

                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <h5 className="mb-2 text-titlecase mb-4">Payments</h5>
                                </div>
                               
                            </div>

                        </div>
                        <div className="seller-payment-page mb-3">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className='seller-payment-inner'>
                                        <div className='seller-payment-inner-left'>
                                            <div className='seller-payment-inner-left-amount mb-3'>
                                                <img src={walletIcon} alt='walletIcon' />
                                                <span>$3,672.59</span>
                                            </div>
                                            <div className='seller-payment-inner-left-avil-bal mb-3'>
                                                <label>Available Balance:</label>
                                                <span>Balance available for withdrawal</span>
                                            </div>
                                            <div className='seller-payment-inner-left-avil-bal mb-3'>
                                                <label>Withdrawn to date:</label>
                                                <span>$3,100.00</span>
                                            </div>
                                            <div className='seller-payment-inner-left-stripeBtn'>
                                                <Link to='#' target='_blank' >
                                                    <img src={stripeIcon} /> <span> Connect Your Stripe Wallet</span>
                                                </Link>
                                            </div>

                                        </div>
                                        <div className='seller-payment-inner-right'>

                                            <div className='withdarw-bal-button'>
                                                <img src={walletIconWhite} alt='walletIconWhite' />
                                                <span>Withdraw Balance</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="seller-payment-page">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className='seller-payment-inner'>
                                        <div className='seller-payment-inner-left'>
                                            <div className='seller-payment-inner-left-amount mb-3'>
                                                <img src={walletIcon} alt='walletIcon' />
                                                <span>1099 Tax Form</span>
                                            </div>
                                            <div className='seller-payment-inner-left-avil-bal '>
                                                <label>Eligibility</label>
                                                <span>The other piece is the 1099 tax form for Affiliates that reach $600+ dollars in earnings over the course of a year</span>
                                            </div>



                                        </div>
                                        <div className='seller-payment-inner-right'>

                                            <Link to='/seller/seller-affiliate-tax-requirements' className='withdarw-bal-button'>
                                                <img src={walletIconWhite} alt='walletIconWhite' />
                                                <span>Submit Form</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    );
}

export default SellerPayments;
