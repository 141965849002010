import React, { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import plusIcon from '../../asstes/dash-icons/plusIcon.svg'
import MagnifyingGlass from '../../asstes/dash-icons/sidebar/MagnifyingGlass.svg'
import LinkReminders from './linkReminders';


const FilesSideNavEdit = ({ handlerCustomReminder }) => {

    const [checkCustomReminder, setCheckCustomReminder] = useState(false);

    const suggestedReminders = [
        {
            id: 1,
            title: 'Vulputate hac augue tempor facilisi dolor.'
        },
        {
            id: 2,
            title: 'Integer consectetur tellus ac sed sagittis aenean'
        },
        {
            id: 3,
            title: 'Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac.'
        },
        {
            id: 4,
            title: 'Convallis massa porta non diam phasellus turpis nibh velit.'
        },
        {
            id: 5,
            title: 'Eget quis viverra ultrices a suscipit duis nulla.'
        },
        {
            id: 4,
            title: 'Convallis massa porta non diam phasellus turpis nibh velit.'
        },
        {
            id: 5,
            title: 'Eget quis viverra ultrices a suscipit duis nulla.'
        },
        {
            id: 4,
            title: 'Convallis massa porta non diam phasellus turpis nibh velit.'
        },
        {
            id: 5,
            title: 'Eget quis viverra ultrices a suscipit duis nulla.'
        },

    ];

    const customeReminders = [
        {
            id: 1,
            title: 'Molestie faucibus diam sed pharetra tristique '
        }
    ]


    const handlerCustomReminderNEW = () => {
        handlerCustomReminder(true);
    }

    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>Edit File</h3>
                <h5></h5>

            </div>
            <div className='side-body'>
                <Form>
                    <Row className="mb-3 dashboardRecentFiles">

                        <Form.Group as={Col} md="6" className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>File Name</Form.Label>
                            <Form.Control type="text" placeholder="File name" />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Upload Date </Form.Label>
                            <Form.Control type="date" placeholder="Name" />

                        </Form.Group>
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen uploadFile'>
                                <div className='title file-name'>file.pdf</div>

                                <div className='button'>
                                    <div className="outline-button-upload">
                                        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.4569 4.79774C15.435 4.74837 14.9056 3.57399 13.7287 2.39712C12.1606 0.828994 10.18 0.000244141 7.99999 0.000244141C5.81999 0.000244141 3.83937 0.828994 2.27124 2.39712C1.09437 3.57399 0.562494 4.75024 0.543119 4.79774C0.51469 4.86169 0.5 4.93089 0.5 5.00087C0.5 5.07085 0.51469 5.14005 0.543119 5.20399C0.564994 5.25337 1.09437 6.42712 2.27124 7.60399C3.83937 9.17149 5.81999 10.0002 7.99999 10.0002C10.18 10.0002 12.1606 9.17149 13.7287 7.60399C14.9056 6.42712 15.435 5.25337 15.4569 5.20399C15.4853 5.14005 15.5 5.07085 15.5 5.00087C15.5 4.93089 15.4853 4.86169 15.4569 4.79774ZM7.99999 9.00024C6.07624 9.00024 4.39562 8.30087 3.00437 6.92212C2.43352 6.35443 1.94786 5.70709 1.56249 5.00024C1.94776 4.29333 2.43343 3.64598 3.00437 3.07837C4.39562 1.69962 6.07624 1.00024 7.99999 1.00024C9.92374 1.00024 11.6044 1.69962 12.9956 3.07837C13.5676 3.64584 14.0543 4.29319 14.4406 5.00024C13.99 5.84149 12.0269 9.00024 7.99999 9.00024ZM7.99999 2.00024C7.40665 2.00024 6.82663 2.17619 6.33328 2.50584C5.83994 2.83548 5.45542 3.30402 5.22836 3.85219C5.00129 4.40037 4.94188 5.00357 5.05764 5.58552C5.17339 6.16746 5.45912 6.70201 5.87867 7.12156C6.29823 7.54112 6.83278 7.82684 7.41472 7.9426C7.99667 8.05836 8.59987 7.99895 9.14804 7.77188C9.69622 7.54482 10.1648 7.1603 10.4944 6.66695C10.824 6.17361 11 5.59359 11 5.00024C10.9992 4.20485 10.6828 3.44227 10.1204 2.87984C9.55797 2.31741 8.79539 2.00107 7.99999 2.00024ZM7.99999 7.00024C7.60443 7.00024 7.21775 6.88295 6.88885 6.66318C6.55996 6.44342 6.30361 6.13106 6.15224 5.76561C6.00086 5.40016 5.96125 4.99803 6.03842 4.61006C6.11559 4.2221 6.30608 3.86574 6.58578 3.58603C6.86549 3.30633 7.22185 3.11584 7.60981 3.03867C7.99778 2.9615 8.39991 3.00111 8.76536 3.15248C9.13081 3.30386 9.44317 3.56021 9.66293 3.8891C9.8827 4.218 9.99999 4.60468 9.99999 5.00024C9.99999 5.53068 9.78928 6.03938 9.41421 6.41446C9.03913 6.78953 8.53043 7.00024 7.99999 7.00024Z" fill="#00487C" />
                                        </svg>

                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.2069 3.58586L9.41438 0.79273C9.32152 0.699847 9.21127 0.626167 9.08993 0.575898C8.96859 0.525629 8.83853 0.499756 8.70719 0.499756C8.57585 0.499756 8.4458 0.525629 8.32446 0.575898C8.20312 0.626167 8.09287 0.699847 8 0.79273L0.29313 8.50023C0.199867 8.59275 0.125926 8.70289 0.0756045 8.82424C0.025283 8.94559 -0.000414649 9.07573 5.05934e-06 9.2071V12.0002C5.05934e-06 12.2654 0.105362 12.5198 0.292898 12.7073C0.480435 12.8949 0.734789 13.0002 1.00001 13.0002H3.79313C3.9245 13.0006 4.05464 12.975 4.17599 12.9246C4.29735 12.8743 4.40748 12.8004 4.50001 12.7071L12.2069 5.00023C12.2998 4.90737 12.3734 4.79712 12.4237 4.67578C12.474 4.55444 12.4999 4.42438 12.4999 4.29304C12.4999 4.1617 12.474 4.03165 12.4237 3.91031C12.3734 3.78897 12.2998 3.67872 12.2069 3.58586ZM3.79313 12.0002H1.00001V9.2071L6.50001 3.7071L9.29313 6.50023L3.79313 12.0002ZM10 5.79273L7.20688 3.00023L8.70688 1.50023L11.5 4.29273L10 5.79273Z" fill="#00487C" />
                                        </svg>

                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 7.50015V11.0001C12 11.2654 11.8946 11.5197 11.7071 11.7073C11.5196 11.8948 11.2652 12.0001 11 12.0001H1C0.734784 12.0001 0.48043 11.8948 0.292893 11.7073C0.105357 11.5197 0 11.2654 0 11.0001V7.50015C0 7.36754 0.0526785 7.24036 0.146447 7.1466C0.240215 7.05283 0.367392 7.00015 0.5 7.00015C0.632608 7.00015 0.759785 7.05283 0.853553 7.1466C0.947321 7.24036 1 7.36754 1 7.50015V11.0001H11V7.50015C11 7.36754 11.0527 7.24036 11.1464 7.1466C11.2402 7.05283 11.3674 7.00015 11.5 7.00015C11.6326 7.00015 11.7598 7.05283 11.8536 7.1466C11.9473 7.24036 12 7.36754 12 7.50015ZM3.85375 3.3539L5.5 1.70702V7.50015C5.5 7.63276 5.55268 7.75993 5.64645 7.8537C5.74021 7.94747 5.86739 8.00015 6 8.00015C6.13261 8.00015 6.25979 7.94747 6.35355 7.8537C6.44732 7.75993 6.5 7.63276 6.5 7.50015V1.70702L8.14625 3.3539C8.24007 3.44772 8.36732 3.50043 8.5 3.50043C8.63268 3.50043 8.75993 3.44772 8.85375 3.3539C8.94757 3.26008 9.00028 3.13283 9.00028 3.00015C9.00028 2.86747 8.94757 2.74022 8.85375 2.6464L6.35375 0.146399C6.30731 0.0999109 6.25217 0.0630315 6.19147 0.0378693C6.13077 0.0127072 6.06571 -0.000244141 6 -0.000244141C5.93429 -0.000244141 5.86923 0.0127072 5.80853 0.0378693C5.74783 0.0630315 5.69269 0.0999109 5.64625 0.146399L3.14625 2.6464C3.05243 2.74022 2.99972 2.86747 2.99972 3.00015C2.99972 3.13283 3.05243 3.26008 3.14625 3.3539C3.24007 3.44772 3.36732 3.50043 3.5 3.50043C3.63268 3.50043 3.75993 3.44772 3.85375 3.3539Z" fill="#00487C" />
                                        </svg>

                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5 2.00024H9V1.50024C9 1.10242 8.84196 0.720889 8.56066 0.439584C8.27936 0.158279 7.89782 0.000244141 7.5 0.000244141H4.5C4.10218 0.000244141 3.72064 0.158279 3.43934 0.439584C3.15804 0.720889 3 1.10242 3 1.50024V2.00024H0.5C0.367392 2.00024 0.240215 2.05292 0.146447 2.14669C0.0526785 2.24046 0 2.36764 0 2.50024C0 2.63285 0.0526785 2.76003 0.146447 2.8538C0.240215 2.94757 0.367392 3.00024 0.5 3.00024H1V12.0002C1 12.2655 1.10536 12.5198 1.29289 12.7074C1.48043 12.8949 1.73478 13.0002 2 13.0002H10C10.2652 13.0002 10.5196 12.8949 10.7071 12.7074C10.8946 12.5198 11 12.2655 11 12.0002V3.00024H11.5C11.6326 3.00024 11.7598 2.94757 11.8536 2.8538C11.9473 2.76003 12 2.63285 12 2.50024C12 2.36764 11.9473 2.24046 11.8536 2.14669C11.7598 2.05292 11.6326 2.00024 11.5 2.00024ZM4 1.50024C4 1.36764 4.05268 1.24046 4.14645 1.14669C4.24021 1.05292 4.36739 1.00024 4.5 1.00024H7.5C7.63261 1.00024 7.75979 1.05292 7.85355 1.14669C7.94732 1.24046 8 1.36764 8 1.50024V2.00024H4V1.50024ZM10 12.0002H2V3.00024H10V12.0002ZM5 5.50024V9.50024C5 9.63285 4.94732 9.76003 4.85355 9.8538C4.75979 9.94757 4.63261 10.0002 4.5 10.0002C4.36739 10.0002 4.24021 9.94757 4.14645 9.8538C4.05268 9.76003 4 9.63285 4 9.50024V5.50024C4 5.36764 4.05268 5.24046 4.14645 5.14669C4.24021 5.05292 4.36739 5.00024 4.5 5.00024C4.63261 5.00024 4.75979 5.05292 4.85355 5.14669C4.94732 5.24046 5 5.36764 5 5.50024ZM8 5.50024V9.50024C8 9.63285 7.94732 9.76003 7.85355 9.8538C7.75979 9.94757 7.63261 10.0002 7.5 10.0002C7.36739 10.0002 7.24021 9.94757 7.14645 9.8538C7.05268 9.76003 7 9.63285 7 9.50024V5.50024C7 5.36764 7.05268 5.24046 7.14645 5.14669C7.24021 5.05292 7.36739 5.00024 7.5 5.00024C7.63261 5.00024 7.75979 5.05292 7.85355 5.14669C7.94732 5.24046 8 5.36764 8 5.50024Z" fill="#00487C" />
                                        </svg>


                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </Row>

                    <div className='create-reminder-parent'>
                        <InputGroup className='search-field '>
                            <InputGroup.Text id="basic-addon1"><img src={MagnifyingGlass} /></InputGroup.Text>
                            <Form.Control type='text' placeholder='Search Reminder' />
                        </InputGroup>
                        <div className='button uploadFile' onClick={handlerCustomReminderNEW}>
                            <div className="outline-button-upload createReminders">

                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.00065 1.33325V10.6666M1.33398 5.99992H10.6673" stroke="#00487C" stroke-linecap="round" />
                                </svg>

                                Custom Reminder
                            </div>
                        </div>
                    </div>




                    <div className='mb-3'>
                        <h1 className='side-nav-main-heading'>Custom reminders</h1>

                        {customeReminders.map((item) => {
                            return (
                                <div className='suggetes-item choose-item-reminder' key={item.id}>
                                    <div className='titleSuggestItem'>{item.title}</div>
                                    <div>
                                        <svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.3507 1.3573L3.7507 6.8573C3.65718 6.94929 3.53125 7.00084 3.40007 7.00084C3.2689 7.00084 3.14297 6.94929 3.04945 6.8573L0.64945 4.50042C0.602625 4.45438 0.565328 4.39956 0.539689 4.3391C0.514049 4.27864 0.500569 4.21373 0.500018 4.14806C0.499466 4.08239 0.511855 4.01725 0.536475 3.95637C0.561096 3.89549 0.597467 3.84006 0.643512 3.79323C0.689557 3.74641 0.744374 3.70911 0.804832 3.68347C0.865291 3.65783 0.930208 3.64435 0.995876 3.6438C1.06154 3.64325 1.12668 3.65564 1.18756 3.68026C1.24844 3.70488 1.30388 3.74125 1.3507 3.7873L3.40007 5.7998L8.65007 0.643546C8.74472 0.550555 8.87244 0.498971 9.00512 0.500143C9.07082 0.500723 9.13576 0.514238 9.19623 0.539916C9.25671 0.565593 9.31153 0.60293 9.35757 0.649796C9.40362 0.696661 9.43998 0.752137 9.46459 0.813056C9.48919 0.873975 9.50156 0.939143 9.50098 1.00484C9.5004 1.07054 9.48688 1.13548 9.4612 1.19595C9.43553 1.25643 9.39819 1.31125 9.35132 1.3573H9.3507ZM15.3569 0.649796C15.3109 0.602895 15.2561 0.565527 15.1956 0.539827C15.1352 0.514127 15.0702 0.5006 15.0045 0.50002C14.9388 0.499439 14.8736 0.511816 14.8127 0.536444C14.7518 0.561071 14.6963 0.597466 14.6494 0.643546L9.39945 5.7998L8.22257 4.64355C8.12793 4.55064 8.00025 4.49913 7.86762 4.50036C7.735 4.50159 7.6083 4.55546 7.51539 4.65011C7.42248 4.74476 7.37097 4.87244 7.37221 5.00506C7.37344 5.13768 7.4273 5.26439 7.52195 5.3573L9.04882 6.8573C9.14234 6.94929 9.26827 7.00084 9.39945 7.00084C9.53063 7.00084 9.65655 6.94929 9.75007 6.8573L15.3501 1.3573C15.397 1.31131 15.4344 1.25653 15.4602 1.19607C15.4859 1.13562 15.4995 1.07068 15.5002 1.00497C15.5008 0.939262 15.4885 0.874072 15.4639 0.813128C15.4393 0.752185 15.403 0.696683 15.3569 0.649796Z" fill="#38A042" />
                                        </svg>
                                    </div>


                                </div>


                            )
                        })}

                        <Form.Group md="12" className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Linked Reminders</Form.Label>
                                <LinkReminders />
                            </div>



                        </Form.Group>
                        <h1 className='side-nav-main-heading'>Suggested reminders</h1>
                    </div>

                    {suggestedReminders.map((item) => {
                        return (
                            <div className='suggetes-item' key={item.id}>
                                <div className='titleSuggestItem'>{item.title}</div>
                                <div><img src={plusIcon} /></div>


                            </div>


                        )
                    })}

                </Form>


            </div>
        </div>
    );
}

export default FilesSideNavEdit;
