import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Select } from './Select';
import placeholderImage from '../../asstes/dash-icons/placeholderImage.svg'

import LinkReminders from './linkReminders';

const ProjectSideNavEdit = () => {
    const [startDate, setStartDate] = useState('2024-03-02');
    const options = [
        { label: "Home Essential", value: 1 },
        { label: "Home Essential", value: 2 },
        { label: "Home Essential", value: 3 },
        { label: "Home Essential", value: 4 },
        { label: "Home Essential", value: 5 },
    ]
    const optionsPurchases = [
        { label: "Home Essential", value: 1 },
        { label: "Home Essential", value: 2 },
        { label: "Home Essential", value: 3 },
        { label: "Home Essential", value: 4 },
        { label: "Home Essential", value: 5 },
    ]
    const [value1, setValue1] = useState([  { label: "Home Essential", value: 1 },]);
    const [valuePurchases, setValuePurchases] = useState([  { label: "Home Essential", value: 1 },  { label: "Home Essential", value: 1 },]);
    const [valueHome, setValueHome] = useState([  { label: "Home Essential", value: 1 },  { label: "Home Essential", value: 1 },  { label: "Home Essential", value: 1 },]);
    const images = [
        {
            id: 1,
            imageUrl: placeholderImage
        },
        {
            id: 2,
            imageUrl: placeholderImage
        },
        {
            id: 3,
            imageUrl: placeholderImage
        },
        {
            id: 4,
            imageUrl: placeholderImage
        },
        {
            id: 5,
            imageUrl: placeholderImage
        },
    ]
    return (
        <div>
          <div className='side-nav'>
            <div className='side-header'>
                <h3>Edit Project</h3>
                <h5></h5>

            </div>
            <div className='side-body'>
                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" className="mb-3" >
                            <Form.Label>Project name</Form.Label>
                            <Form.Control type="text" defaultValue="Furnace" placeholder="Name" />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Start <span className='optional-text'></span></Form.Label>
                            <Form.Control type="date" value={startDate} placeholder="Name" />

                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>End <span className='optional-text'></span></Form.Label>
                            <Form.Control type="date" value={startDate} placeholder="Name" />

                        </Form.Group>
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Files</div>

                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                        </svg>
                                        Add file
                                        <input type='file' className='chooeseFile' />


                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Images</div>

                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                        </svg>
                                        Add image
                                        <input type='file' className='chooeseFile' />
                                    </div>
                                </div>
                                <div className='listOfImages'>
                                    {images.map((img) => {
                                        return (
                                            <div className='' key={img.id}><img src={img.imageUrl} alt='image' /></div>

                                        )
                                    })}
                                </div>
                            </div>
                        </Form.Group>


                        <Form.Group as={Col} md="12" className="mb-3" controlId="">
                            <Form.Label>Linked Purchases</Form.Label>

                            <Select
                                multiple
                                options={optionsPurchases}
                                value={valuePurchases}
                                onChange={o => setValuePurchases(o)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mb-3" controlId="">
                            <Form.Label>Linked services</Form.Label>

                            <Select
                                multiple
                                options={options}
                                value={value1}
                                onChange={o => setValue1(o)}
                            />
                        </Form.Group>

                        <Form.Group md="12" className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Linked Reminders</Form.Label>
                                <LinkReminders />
                            </div>



                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mb-3" controlId="">
                            <Form.Label>Linked Home Essentials</Form.Label>

                            <Select
                                multiple
                                options={options}
                                value={valueHome}
                                onChange={o => setValueHome(o)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder='Type here...' />
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
        </div>
    );
}

export default ProjectSideNavEdit;
