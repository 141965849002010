import React, { useState } from 'react';
import downArrow from '../assets/icons/downArrow.svg'

const SelectDropDownYTD = () => {
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('YTD');
    const options = ['YTD', 'Lifetime'];


    const handleInitClick = (e) => {
        // Prevent toggling visibility if clicking on an option
        e.stopPropagation();
        setOptionsVisible(!optionsVisible);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setOptionsVisible(false);
    };

    return (

        <div className='selectDropDown' onClick={handleInitClick}>

            <img src={downArrow} alt='downArrow' onClick={handleInitClick} />

            <ul className="dropdown-list" onClick={handleInitClick}>
                <li className="init" onClick={handleInitClick}>{selectedOption}</li>
                {optionsVisible && (
                    <>
                    
                    {options
                        .filter(option => option !== selectedOption)
                        .map(option => (
                            <li key={option} data-value={option} onClick={() => handleOptionClick(option)}>
                                {option}
                            </li>
                        ))
                    }
                    </>
                )}
            </ul>
        </div>
    );
};

export default SelectDropDownYTD;
