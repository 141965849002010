import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { Select } from './Select'; // Assuming this is a custom select component
import { listOfFrequencies, fetchHomeEssentials } from '../../../axiosCalls/userdashboard'; // Import API call function

const ReminderSideNavCreate = ({ suggestedReminderAdd }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [selectedHomeEssentials, setSelectedHomeEssentials] = useState([]);
    const [customDate, setCustomDate] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState({}); // Initialize as an empty object
    const [frequencies, setFrequencies] = useState([]);
    const [homeEssentials, setHomeEssentials] = useState([]); // State for home essentials
    const [open, setOpen] = useState(false);

    // Fetching frequencies data
    useEffect(() => {
        const fetchFrequencies = async () => {
            try {
                const response = await listOfFrequencies();
                setFrequencies(response.data);
            } catch (error) {
                console.error("Error fetching frequencies:", error);
            }
        };
        fetchFrequencies();
    }, []);

    // Fetching home essentials data
    useEffect(() => {
        const fetchEssentials = async () => {
            try {
                const response = await fetchHomeEssentials(); // Assuming this function returns the home essentials data
                const optionsData = response.data.items.map(item => ({
                    label: `${item.type} - ${item.model_number}`, // Display type and model number as label
                    value: item.id, // ID as value
                }));
                setHomeEssentials(optionsData);
            } catch (error) {
                console.error("Error fetching home essentials:", error);
            }
        };
        fetchEssentials();
    }, []);

    useEffect(() => {
        suggestedReminderAdd(selectedReminder);
    }, [selectedReminder, suggestedReminderAdd]); // Ensure that suggestedReminderAdd is updated when selectedReminder changes

    // Handle frequency change
    const handleFrequencyChange = (e) => {
        const selectedFrequency = e.target.value;
        const frequencyData = frequencies.find(freq => freq.name === selectedFrequency);

        if (frequencyData) {
            const homeEssentialIds = selectedHomeEssentials.map(essential => essential.value);

            setSelectedReminder(prevState => ({
                ...prevState,
                frequency: frequencyData.name,
                reminder_frequency: frequencyData.reminder_frequency,
                time: frequencyData.time,
                frequency_id: frequencyData.id,
                linked_home_essentials: homeEssentialIds // Set the selected home essentials here
            }));
        }
    };

    // Handle home essential selection change
    const handleHomeEssentialChange = (selected) => {
        setSelectedHomeEssentials(selected);

        // Update the selected reminder with linked home essentials whenever this changes
        setSelectedReminder(prevState => ({
            ...prevState,
            linked_home_essentials: selected
        }));
    };

    // Handle repeat change
    const [repeatOption, setRepeatOption] = useState('never');

    const handleRepeatChange = (event) => {
        setRepeatOption(event.target.value);
        setCustomDate(event.target.value === 'Custom');
    };

    return (
        <div style={{ width: "100%" }}>
            <div className='side-header'>
                <h3>Add Reminder</h3>
            </div>
            <div className='side-body CraeteReminderSideBody'>
                <Form>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(e) => setSelectedReminder({ ...selectedReminder, title: e.target.value })}
                                required
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                onChange={(e) => setSelectedReminder({ ...selectedReminder, description: e.target.value })}
                                required
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Frequency</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={handleFrequencyChange}
                                required>
                                <option value="" disabled>Select frequency</option>
                                {frequencies.map((freq) => (
                                    <option key={freq.id} value={freq.name}>
                                        {freq.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Reminder Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                onChange={(e) => setSelectedReminder({ ...selectedReminder, date: e.target.value })}
                                required
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Time</Form.Label>
                            <Form.Control
                                type="time"
                                onChange={(e) => setSelectedReminder({ ...selectedReminder, time: e.target.value })}
                                required
                            />
                        </Form.Group>

                        {/* Linked Home Essential */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Linked Home Essential</Form.Label>
                            <Select
                                multiple
                                options={homeEssentials} // Updated options to use home essentials from API
                                value={selectedHomeEssentials}
                                onChange={handleHomeEssentialChange} // Use the dedicated handler
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default ReminderSideNavCreate;
