import React, { useState } from "react";
import DataTable, { Alignment, Direction } from "react-data-table-component";
import lampIcon from '../../asstes/dash-icons/sidebar/Lamp.svg'
import ReceiptIcon from '../../asstes/dash-icons/sidebar/Receipt.svg'
import BroomIcon from '../../asstes/dash-icons/sidebar/Broom.svg'
import PurchasesServiceSideView from "./purchasesServiceSideView";
import PurchasesSideNavEdit from "./purchasesSideNavEdit";

const columns = [
  {
    name: "Type",
    selector: (row) => row.type,
    sortable: true,
    reorder: true,
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    reorder: true,
  },
  {
    name: "Make",
    selector: (row) => row.make,
    sortable: true,
    reorder: true,
  },
  {
    name: "Model #",
    selector: (row) => row.model,
    sortable: true,
    reorder: true,
  },
  {
    name: "Vendor",
    selector: (row) => row.vendor,
    sortable: true,
    reorder: true,
  },
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
    reorder: true,
  },
  {
    name: "Cost",
    selector: (row) => row.cost,
    sortable: true,
    reorder: true,
  },
];

const data = [
  {
    id: 1,
    type: <span className="services-badge type-green" ><img src={lampIcon} />Home Essential</span>,
    name: "Refrigerator",
    make: "Morbi et",
    model: "Pulvinar ac",
    vendor: "Jordane Enterprises",
    date: "04/01/2023",
    cost: "$4,985",
  },
  {
    id: 2,
    type: <span className="services-badge type-blue"><img src={ReceiptIcon} />Other</span>,
    name: "Refrigerator",
    date: "04/01/2023",
    make: "Morbi et",
    model: "Pulvinar ac",
    vendor: "Jordane Enterprises",
    cost: "$4,985",
  },
  {
    id: 3,
    type: <span className="services-badge type-camel" ><img src={BroomIcon} />Service</span>,
    name: "Refrigerator",
    make: "Morbi et",
    model: "Pulvinar ac",
    vendor: "Jordane Enterprises",
    date: "04/01/2023",
    cost: "$4,985",
  },
  {
    id: 4,
    type: <span className="services-badge  type-green" ><img src={lampIcon} />Home Essential</span>,
    name: "Refrigerator",
    make: "Morbi et",
    model: "Pulvinar ac",
    vendor: "Jordane Enterprises",
    date: "04/01/2023",
    cost: "$4,985",
  },

  {
    id: 5,
    type: <span className="services-badge  type-blue" ><img src={ReceiptIcon} />Other</span>,
    name: "Cleaning",
    date: "04/01/2023",
    make: "Morbi et",
    model: "Pulvinar ac",
    vendor: "Jordane Enterprises",
    cost: "$4,985",
  },
  {
    id: 6,
    type: <span className="services-badge  type-camel"  ><img src={BroomIcon} />Service</span>,
    name: "Refrigerator",
    make: "Morbi et",
    model: "Pulvinar ac",
    vendor: "Jordane Enterprises",
    date: "04/01/2023",
    cost: "$4,985",
  },

];



const PurchasesServicesTable = () => {
  const [sideNavWidth, setSideNavWidth] = useState('0px');
  const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
  const [continueChange, setContinueChange] = useState('viewStep');


  const handleRowSideNavOpen = () => {
    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    setContinueChange('viewStep');
    document.body.classList.add('no-scroll');


  }
  const handlerCancelSideNav = () => {
    setSideNavWidth('sidenavPopuWidthHide');
    setsideNavWidthOverlay('0%');
    setContinueChange('viewStep');
    document.body.classList.remove('no-scroll');



  }

  const handlerChangeToEdit = () => {
    setContinueChange('editStep');
  }
  return (
    <div className="PurcheseAndServicesTable">
      <DataTable
        columns={columns}
        data={data}
        defaultSortFieldId={1}
        selectableRows={false}
        selectableRowsNoSelectAll={false}
        selectableRowsVisibleOnly={false}
        selectableRowsHighlight={false}
        selectableRowsSingle={false}
        expandableRows={false}
        onRowClicked={handleRowSideNavOpen} // Attach click event handler
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
        pagination={true}
        highlightOnHover={true}
        striped={false}
        pointerOnHover={true}
        dense={false}
        persistTableHead={true}
        noHeader={false}
        fixedHeader={false}
        fixedHeaderScrollHeight={"300px"}
        progressPending={false}
        noTableHead={false}
        noContextMenu={false}
        direction={Direction.AUTO}
        subHeader={false}
        subHeaderAlign={Alignment.RIGHT}
        subHeaderWrap={true}
        responsive={true}
        disabled={false}
      />
      <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

      <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

{
  continueChange === 'editStep' ?  <PurchasesSideNavEdit />: <PurchasesServiceSideView />
}
       
        <div className='side-footer'>

          <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

          <button className='footer-button-primary ' onClick={handlerChangeToEdit}>{continueChange === 'editStep' ? 'Update':'Edit'}</button>


        </div>

      </div>
    </div>
  );
}

export default PurchasesServicesTable;
