import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import MagnifyingGlass from '../asstes/dash-icons/sidebar/MagnifyingGlass.svg'
import DataTable, { Alignment, Direction } from 'react-data-table-component';

import pdfIcon from '../asstes/dash-icons/pdfIcon.svg'
import eyeIcon from '../asstes/dash-icons/eyeIcon.svg'
import downloadIcon from '../asstes/dash-icons/downloadIcon.svg'
import deleteIcon from '../asstes/dash-icons/deleteIcon.svg'

import previewImg from '../asstes/images/preview-img.jpg'
import previewPDF from '../asstes/images/preview-pdf.svg'
import FilesSideNavAdd from '../components/inc/filesSideNavAdd';
import FilesSideNavEdit from '../components/inc/filesSideNavEdit';
import ReminderSideNavCreate from '../components/inc/ReminderSideNavCreate';
import FilesSideNavView from '../components/inc/filesSideNavView';


const Files = () => {
    const [tabSelection, setTabSelection] = useState('table');
    const [continueChange, setContinueChange] = useState('createStep');

    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');

    const [checkCustomReminder, setCheckCustomReminder] = useState(false);

    const previews = [
        { type: 'pdf', src: previewPDF, name: 'document_name.pdf' },
        { type: 'image', src: previewImg, name: 'document_name.png' },
        { type: 'pdf', src: previewPDF, name: 'document_name.pdf' },
        { type: 'image', src: previewImg, name: 'document_name.png' },
        { type: 'image', src: previewImg, name: 'document_name.pdf' },
        { type: 'pdf', src: previewPDF, name: 'document_name.pdf' },
        { type: 'image', src: previewImg, name: 'document_name.pdf' },
        { type: 'pdf', src: previewPDF, name: 'document_name.pdf' },
        { type: 'pdf', src: previewPDF, name: 'document_name.pdf' },
        { type: 'image', src: previewImg, name: 'document_name.png' },
        { type: 'pdf', src: previewPDF, name: 'document_name.pdf' },
        { type: 'image', src: previewImg, name: 'document_name.png' },
        { type: 'image', src: previewImg, name: 'document_name.pdf' },
        { type: 'pdf', src: previewPDF, name: 'document_name.pdf' },
        { type: 'image', src: previewImg, name: 'document_name.pdf' },
        { type: 'pdf', src: previewPDF, name: 'document_name.pdf' },
        // Add more preview objects as needed
    ];
    const columnsFiles = [
        {
            name: "File name",
            selector: (row) => row.filename,
            sortable: false,
            reorder: true,
        },
        {
            name: "Related to",
            selector: (row) => row.Type,
            sortable: false,
            reorder: true,
        },
        {
            name: "Date",
            selector: (row) => row.date,
            sortable: false,
            reorder: true,
        },
        {
            name: "",
            selector: (row) => row.action,
            sortable: false,
            reorder: true,
        },


    ];
    const dataFiles = [
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-green" >Home Essential</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-red" >Projects</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-blue" >Purchase</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-camel" >Services</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-green" >Home Essential</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-red" >Projects</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-blue" >Purchase</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-camel" >Services</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-green" >Home Essential</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-red" >Projects</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-blue" >Purchase</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-camel" >Services</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-green" >Home Essential</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-red" >Projects</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-blue" >Purchase</span>,
            date: '2/11/12',

        },
        {
            id: 1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action: <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>,
            Type: <span className="services-badge type-camel" >Services</span>,
            date: '2/11/12',

        },

    ]


    const handlerOpenViewFile = () => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setContinueChange('ViewFileStep');
        setCheckCustomReminder(false);
        document.body.classList.add('no-scroll');



    }
    const handlerNewSideNave = () => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setContinueChange('createStep');
        setCheckCustomReminder(false);
        document.body.classList.add('no-scroll');



    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        setCheckCustomReminder(false);
        document.body.classList.remove('no-scroll');




    }

    const handlerCustomReminder = (isChecked) => {
        setCheckCustomReminder(isChecked);
    }

    const handlerChangeForStep = () => {
        if (continueChange === 'ViewFileStep') {
            setContinueChange('EditFileStep');
        }
    }

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <h5 className="mb-2 text-titlecase mb-4">Files</h5>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">
                            <div className="col-md-4 top-text-head">
                                <div className="reminder-top-button">


                                    <button className="filled-btn" onClick={handlerNewSideNave}>

                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673" stroke="white" strokeLinecap="round" />
                                        </svg>

                                        Add File</button>

                                </div>
                            </div>


                        </div>

                    </div>


                    <div className="row files_filters">

                        <div className='col-md-5 filesSearchArea'>
                            <Form.Label></Form.Label>

                            <InputGroup className='search-field'>
                                <InputGroup.Text id="basic-addon1"><img src={MagnifyingGlass} /></InputGroup.Text>

                                <Form.Control type='text' placeholder='Search' />
                            </InputGroup>
                        </div>
                        <div className='col-md-5 d-flex  File-sorting-table'>
                            <div className='col-md-6'>
                                <Form.Label>Sort By</Form.Label>
                                <Form.Select>

                                    <option>By Upload Date</option>
                                </Form.Select>

                            </div>
                            <div className='col-md-6'>
                                <Form.Label>Related to</Form.Label>
                                <Form.Select>
                                    <option>Home Essentials</option>
                                </Form.Select>
                            </div>
                        </div>

                        <div className='col-md-2 d-flex tabs-files'>
                            <div className=''>
                                <Form.Label style={{ width: "100%" }}></Form.Label>

                                <div className="reminder-top-button">

                                    <button className={tabSelection == 'preview' ? 'outline reminder-active' : 'outline'} onClick={() => { setTabSelection('preview') }}>

                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 2.5L2.5 2.5C2.23478 2.5 1.98043 2.60536 1.79289 2.79289C1.60536 2.98043 1.5 3.23478 1.5 3.5L1.5 12.5C1.5 12.7652 1.60536 13.0196 1.79289 13.2071C1.98043 13.3946 2.23478 13.5 2.5 13.5L13.5 13.5C13.7652 13.5 14.0196 13.3946 14.2071 13.2071C14.3946 13.0196 14.5 12.7652 14.5 12.5L14.5 3.5C14.5 3.23478 14.3946 2.98043 14.2071 2.79289C14.0196 2.60536 13.7652 2.5 13.5 2.5ZM13.5 3.5L13.5 9.92188L11.8706 8.29313C11.7778 8.20024 11.6675 8.12656 11.5462 8.07629C11.4248 8.02602 11.2948 8.00015 11.1634 8.00015C11.0321 8.00015 10.902 8.02602 10.7807 8.07629C10.6594 8.12656 10.5491 8.20024 10.4562 8.29313L9.20625 9.54313L6.45625 6.79313C6.26873 6.60573 6.01448 6.50046 5.74937 6.50046C5.48427 6.50046 5.23002 6.60573 5.0425 6.79313L2.5 9.33562L2.5 3.5L13.5 3.5ZM2.5 10.75L5.75 7.5L10.75 12.5L2.5 12.5L2.5 10.75ZM13.5 12.5L12.1644 12.5L9.91437 10.25L11.1644 9L13.5 11.3363L13.5 12.5ZM9 6.25C9 6.10166 9.04399 5.95666 9.1264 5.83332C9.20881 5.70999 9.32594 5.61386 9.46299 5.55709C9.60003 5.50033 9.75083 5.48547 9.89632 5.51441C10.0418 5.54335 10.1754 5.61478 10.2803 5.71967C10.3852 5.82456 10.4566 5.9582 10.4856 6.10368C10.5145 6.24917 10.4997 6.39997 10.4429 6.53701C10.3861 6.67406 10.29 6.79119 10.1667 6.8736C10.0433 6.95601 9.89834 7 9.75 7C9.55109 7 9.36032 6.92098 9.21967 6.78033C9.07902 6.63968 9 6.44891 9 6.25Z" fill="#00487C" />
                                        </svg>

                                        Preview</button>
                                </div>
                            </div>

                            <div className=''>


                                <Form.Label style={{ width: "100%" }}></Form.Label>
                                <div className="reminder-top-button">

                                    <button className={tabSelection == 'table' ? 'outline reminder-active' : 'outline'} onClick={() => { setTabSelection('table') }}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 3.00195L2 3.00195C1.86739 3.00195 1.74021 3.05463 1.64645 3.1484C1.55268 3.24217 1.5 3.36934 1.5 3.50195L1.5 12.002C1.5 12.2672 1.60536 12.5215 1.79289 12.7091C1.98043 12.8966 2.23478 13.002 2.5 13.002L13.5 13.002C13.7652 13.002 14.0196 12.8966 14.2071 12.7091C14.3946 12.5215 14.5 12.2672 14.5 12.002L14.5 3.50195C14.5 3.36935 14.4473 3.24217 14.3536 3.1484C14.2598 3.05463 14.1326 3.00195 14 3.00195ZM2.5 7.00195L5 7.00195L5 9.00195L2.5 9.00195L2.5 7.00195ZM6 7.00195L13.5 7.00195L13.5 9.00195L6 9.00195L6 7.00195ZM13.5 4.00195L13.5 6.00195L2.5 6.00195L2.5 4.00195L13.5 4.00195ZM2.5 10.002L5 10.002L5 12.002L2.5 12.002L2.5 10.002ZM13.5 12.002L6 12.002L6 10.002L13.5 10.002L13.5 12.002Z" fill="#85898F" />
                                        </svg>
                                        Table</button>
                                </div>
                            </div>




                        </div>

                    </div>

                    {
                        tabSelection === 'table' ?



                            <div className='col-md-12 mb-3'>

                                <div className="recentFilesTable listofALLFiles">

                                    <DataTable
                                        columns={columnsFiles}
                                        data={dataFiles}
                                        defaultSortFieldId={2}
                                        selectableRows={false}
                                        selectableRowsNoSelectAll={false}
                                        selectableRowsVisibleOnly={false}
                                        selectableRowsHighlight={false}
                                        selectableRowsSingle={false}
                                        expandableRows={false}
                                        expandOnRowClicked={false}
                                        expandOnRowDoubleClicked={false}
                                        expandableRowsHideExpander={false}
                                        pagination={true}
                                        onRowClicked={handlerOpenViewFile}
                                        highlightOnHover={true}
                                        striped={false}
                                        pointerOnHover={false}
                                        dense={false}
                                        persistTableHead={true}
                                        noHeader={false}
                                        fixedHeader={false}
                                        fixedHeaderScrollHeight={"300px"}
                                        progressPending={false}
                                        noTableHead={false}
                                        noContextMenu={false}
                                        direction={Direction.AUTO}
                                        subHeader={false}
                                        subHeaderAlign={Alignment.RIGHT}
                                        subHeaderWrap={true}
                                        responsive={true}
                                        disabled={false}
                                        className='proteryReport'

                                    />

                                </div>
                            </div>
                            :

                            <div className='row mt-3'>
                                {previews.map((preview, index) => (
                                    <div onClick={handlerOpenViewFile} className='previewParenet col-md-3 mb-3' key={index}>
                                        <div className='preview-item'>
                                            <div className='preview-item-image-section'>
                                                {preview.type === 'image' ? (
                                                    <img src={preview.src} alt={preview.name} />
                                                ) : (
                                                    <img src={preview.src} alt={preview.name} type='application/pdf' />
                                                )}
                                            </div>
                                            <div className='preview-item-footer'>
                                                <div className='fileNametext'>{preview.name}</div>
                                                <div className='fileAction'>
                                                    <span><img src={eyeIcon} alt="eye" /></span>
                                                    <span><img src={downloadIcon} alt="download" /></span>
                                                    <span><img src={deleteIcon} alt="delete" /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>


                    }


                </div>



            </div>

            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            {checkCustomReminder ?
                <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                    <ReminderSideNavCreate />


                    <div className='side-footer'>

                        <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

                        <button className='footer-button-primary ' >  {continueChange === 'createStep' ? "Save" : "Save"}</button>
                        {continueChange === 'createStep' ? '' : <button className='footer-button-delete footer-button-back' >Delete</button>}



                    </div>

                </div>

                :

                <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                    {continueChange === 'createStep' ? <FilesSideNavAdd handlerCustomReminder={handlerCustomReminder} /> : continueChange === 'ViewFileStep' ? <FilesSideNavView /> : <FilesSideNavEdit handlerCustomReminder={handlerCustomReminder} />}


                    <div className='side-footer'>

                        <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

                        <button className='footer-button-primary ' onClick={handlerChangeForStep}>  {continueChange === 'createStep' ? "Save" : continueChange === 'ViewFileStep' ? "Edit" : "Save"}</button>
                        {continueChange === 'createStep' || continueChange === 'ViewFileStep' ? '' : <button className='footer-button-delete footer-button-back' >Delete</button>}


                    </div>

                </div>
            }






        </div>
    );
}


export default Files;
