import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import RecentEarning from '../inc/recent-earning';
import MyNetworkTable from '../inc/myNetworkTable';
import { Link } from 'react-router-dom';
import swtichIcon from '../assets/icons/refresh-cw.svg';
import MagnifyingGlass from '../../user-dashboard/asstes/dash-icons/sidebar/MagnifyingGlass.svg'



const MyNetwork = () => {
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">




                        <div className="">

                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <h5 className="mb-2 text-titlecase mb-3">My Network</h5>
                                </div>
                               
                            </div>


                            <div className=''>
                                <div className="row mb-1">
                                    <div className="col-md-9 seller-my-network-sorting">
                                        <InputGroup className='search-field mb-1 mt-0'>
                                            <InputGroup.Text id="basic-addon1"><img src={MagnifyingGlass} /></InputGroup.Text>
                                            <Form.Control type='text' placeholder='Search ' />
                                        </InputGroup>
                                    </div>

                                    <div className="col-md-3 card-right-action mynetworkSorting mb-2">
                                        <div className='recent-earning-sortlble'>


                                            <label>Sort by</label>
                                            <Form.Select >
                                                <option>Alphabet</option>
                                                <option>Date</option>

                                            </Form.Select>
                                        </div>

                                    </div>



                                </div>
                                <MyNetworkTable />

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyNetwork;
