import React, { useState, useEffect } from 'react';
import plusIcon from '../../asstes/dash-icons/plusIcon.svg';
import { Form, InputGroup } from 'react-bootstrap';
import MagnifyingGlass from '../../asstes/dash-icons/sidebar/MagnifyingGlass.svg';
import { getCustomReminders, getSuggestedReminders, listOfFrequencies } from '../../../axiosCalls/userdashboard';

const ReminderSideNav = ({ suggestedReminderAdd, handlerCustomReminder, backButton, buttonShowingBackWith }) => {
    const [suggestedReminders, setSuggestedReminders] = useState([]);
    const [customReminders, setCustomReminders] = useState([]);
    const [frequencies, setFrequencies] = useState([]);
    const [selectedReminder, setSelectedReminder] = useState(null);
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchReminders = async () => {
            try {
                const reminders = await getSuggestedReminders();
                const customReminderRes = await getCustomReminders();
                setSuggestedReminders(reminders.data);
                setCustomReminders(customReminderRes.data);
            } catch (error) {
                console.error("Error fetching reminders:", error);
            }
        };

        const fetchFrequencies = async () => {
            try {
                const response = await listOfFrequencies();
                setFrequencies(response.data);
            } catch (error) {
                console.error("Error fetching frequencies:", error);
            }
        };

        fetchReminders();
        fetchFrequencies();
    }, []);

    useEffect(() => {
        if (backButton) {
            suggestedReminderAdd(null);
            setOpen(false);
        }
    }, [backButton]);

    useEffect(() => {
        if (!backButton) {
            suggestedReminderAdd(selectedReminder);
        }
    }, [selectedReminder, backButton]);

    const handleReminderClick = (reminder) => {
        setSelectedReminder(reminder);
        setOpen(true);
        buttonShowingBackWith(false);
    };

    const handleFrequencyChange = (e) => {
        const selectedFrequency = e.target.value;
        const frequencyData = frequencies.find(freq => freq.name === selectedFrequency);

        if (frequencyData) {
            setSelectedReminder(prevState => ({
                ...prevState,
                frequency: frequencyData.name,
                reminder_frequency: frequencyData.reminder_frequency,
                time: frequencyData.time,
                frequency_id: frequencyData.id
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Reminder details:', selectedReminder);
    };

    // Filter reminders based on search term
    const filteredCustomReminders = customReminders
        .filter(reminder => reminder.title.toLowerCase().includes(searchTerm.toLowerCase()))
        .slice(0, 5); // Limit to first 5 reminders

    const filteredSuggestedReminders = suggestedReminders
        .filter(reminder => reminder.title.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <>
            <div className='side-header'>
                <h3>Add Reminders</h3>
                <h5>Search for a Reminder or select one or more reminders to add to your calendar</h5>
                <Form>
                    <div className='create-reminder-parent'>
                        <InputGroup className='search-field'>
                            <InputGroup.Text id="basic-addon1">
                                <img src={MagnifyingGlass} alt="Search" />
                            </InputGroup.Text>
                            <Form.Control
                                type='text'
                                placeholder='Search Reminder'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                            />
                        </InputGroup>
                        <div className='button uploadFile' onClick={() => handlerCustomReminder(null)}>
                            <div className="outline-button-upload createReminders">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.00065 1.33325V10.6666M1.33398 5.99992H10.6673" stroke="#00487C" strokeLinecap="round" />
                                </svg>
                                Custom reminder
                            </div>
                        </div>

                        {!open ? (
                            filteredCustomReminders.map((item) => (
                                <div
                                    className='suggetes-item'
                                    key={item.id}
                                    onClick={() => handleReminderClick(item)}>
                                    <div className='titleSuggestItem'>{item.title}</div>
                                    <div><img src={plusIcon} alt="Add" /></div>
                                </div>
                            ))
                        ) : ''
                        }
                    </div>
                </Form>
            </div>

            {!open ? (
                <div className='side-body'>
                    <h1 className='side-nav-main-heading'>Suggested reminders</h1>
                    <div className='inner-body'>
                        {filteredSuggestedReminders.map((item) => (
                            <div
                                className='suggetes-item'
                                key={item.id}
                                onClick={() => handleReminderClick(item)}>
                                <div className='titleSuggestItem'>{item.title}</div>
                                <div><img src={plusIcon} alt="Add" /></div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className='side-body'>
                    <h1 className='side-nav-main-heading'>Add Reminders</h1>
                    <div className='inner-body'>
                        <div className='reminder-details'>
                            {selectedReminder && (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formTitle">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={selectedReminder.title}
                                            onChange={(e) => setSelectedReminder({ ...selectedReminder, title: e.target.value })}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formDescription">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={selectedReminder.description}
                                            onChange={(e) => setSelectedReminder({ ...selectedReminder, description: e.target.value })}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formFrequency">
                                        <Form.Label>Frequency</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={selectedReminder.frequency || ''}
                                            onChange={handleFrequencyChange}
                                            required>
                                            <option value="" disabled>Select frequency</option>
                                            {frequencies.map((freq) => (
                                                <option key={freq.id} value={freq.name}>
                                                    {freq.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="formDate">
                                        <Form.Label>Reminder Start Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={selectedReminder.date}
                                            onChange={(e) => setSelectedReminder({ ...selectedReminder, date: e.target.value })}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formTime">
                                        <Form.Label>Time</Form.Label>
                                        <Form.Control
                                            type="time"
                                            value={selectedReminder.time}
                                            onChange={(e) => setSelectedReminder({ ...selectedReminder, time: e.target.value })}
                                            required
                                        />
                                    </Form.Group>
                                </Form>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ReminderSideNav;
