import React, { useEffect, useState, useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import placeholderImage from '../../asstes/dash-icons/placeholderImage.svg';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import { getPropertyCategory, getPropertyTypes, getStates } from '../../../axiosCalls/userdashboard';

const PropertyNewSideNav = ({ setFormData }) => {
    const [states, setStates] = useState([]);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [propertyCategories, setPropertyCategories] = useState([]);
    const [propertyImage, setPropertyImage] = useState([]);
    const [imageURL, setImageURL] = useState(null);
    const [images, setImages] = useState([]);
    const [postThumNailImage, setPostThumNailImage] = useState();
    const [postThumNailFiles, setPostThumNailFiles] = useState([]);
    const [formValues, setFormValues] = useState({
        propertyName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        propertyType: '',
        propertyCategory: '',
        notes: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleImageChange = (event) => {
        const newFile = [];
        const file = event.target.files[0];

        setPostThumNailImage(file);

        setFormValues({
            ...formValues,
            ['postThumNailImage']: file,
        });

        if (file) {
            const url = URL.createObjectURL(file);
            setImageURL(url);
            newFile.push({
                id: Math.random(),
                name: file.name,
                type: file.type,
                url,
            });
            setPropertyImage(newFile);
        }
    };

    const handleImagesChange = (event) => {
        const selectedFiles = event.target.files;
        const newFiles = [];

        if (images.length + selectedFiles.length > 10) {
            alert("You can upload a maximum of 10 files.");
            return;
        }

        setFormValues({
            ...formValues,
            ['postThumNailFiles']: selectedFiles,
        });

        setPostThumNailFiles(selectedFiles);

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const url = URL.createObjectURL(file);
            newFiles.push({
                id: Math.random(),
                name: file.name,
                type: file.type,
                url,
            });
        }

        setImages((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const removeImage = (id) => {
        setImages((prevImages) => prevImages.filter(img => img.id !== id));
    };

    const handleRemoveImage = () => {
        setImageURL(null);
    };

    const fetchBasicData = useCallback(async () => {
        try {
            const responseStates = await getStates();
            setStates(responseStates.data);
        } catch (error) {
            console.error(error);
        }

        try {
            const responsePropertyTypes = await getPropertyTypes();
            setPropertyTypes(responsePropertyTypes.data);
        } catch (error) {
            console.error(error);
        }

        try {
            const responsePropertyCategories = await getPropertyCategory();
            setPropertyCategories(responsePropertyCategories.data);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchBasicData();
    }, [fetchBasicData]);

    useEffect(() => {
        setFormData({ ...formValues, postThumNailFiles, postThumNailImage });
    }, [formValues, postThumNailFiles, postThumNailImage]);

    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>Add New Property</h3>
            </div>
            <div className='side-body'>
                <Form>
                    <Row className="mb-3">
                        {/* Property Name */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Property Name</Form.Label>
                            <Form.Control type="text" name='propertyName' onChange={handleInputChange} />
                        </Form.Group>

                        {/* Property Image */}
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Property Image</div>
                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                        </svg>
                                        Add Property Image
                                        <input type='file' className='chooeseFile' onChange={handleImageChange} accept=".png, .jpeg, .jpg, .webp" />
                                    </div>
                                </div>
                            </div>
                            <div className='listOfImages'>
                                {imageURL && (
                                    <div className='image-choosed'>
                                        <img src={imageURL} alt='Selected property' />
                                        <button onClick={handleRemoveImage} className='image-choosed-crossed'>&times;</button>
                                    </div>
                                )}
                            </div>
                        </Form.Group>

                        {/* Files */}
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Files</div>
                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                        </svg>
                                        Add file
                                        <input
                                            type='file'
                                            className='chooeseFile'
                                            multiple
                                            accept=".png, .jpeg, .jpg, .webp, .pdf"
                                            onChange={handleImagesChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='listOfImages'>
                                {images.map((file) => (
                                    <div className='image-choosed' key={file.id}>
                                        {file.type === 'application/pdf' ? (
                                            <img src={pdfIcon} alt='PDF Icon' />
                                        ) : (
                                            <img src={file.url} alt='Selected' />
                                        )}
                                        <button onClick={() => removeImage(file.id)} className='image-choosed-crossed'>
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>

                        {/* Address Fields */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Address 1</Form.Label>
                            <Form.Control type="text" placeholder='123 Main Street' name='address1' onChange={handleInputChange} />
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control type="text" placeholder='such as apartment number, suite, floor, etc.' name='address2' onChange={handleInputChange} />
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder='AnyTown' name='city' onChange={handleInputChange} />
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>State</Form.Label>
                            <Form.Select name='state' onChange={handleInputChange}>
                                {states.map(state => (
                                    <option key={state.term_id} value={state.term_id}>{state.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="text" placeholder='12345' name='postalCode' onChange={handleInputChange} />
                        </Form.Group>

                        {/* Property Type */}
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Property Type</Form.Label>
                            <Form.Select name='propertyType' onChange={handleInputChange}>
                                {propertyTypes.map(type => (
                                    <option key={type.term_id} value={type.term_id}>{type.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        {/* Property Category */}
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Property Category</Form.Label>
                            <Form.Select name='propertyCategory' onChange={handleInputChange}>
                                {propertyCategories.map(category => (
                                    <option key={category.term_id} value={category.term_id}>{category.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        {/* Notes */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder='Type something...' name='notes' onChange={handleInputChange} />
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default PropertyNewSideNav;
