import React, { useState } from 'react';
import DataTable,{ Alignment, Direction } from "react-data-table-component";
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg'
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg'
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg'
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg'
import FilesSideNavAdd from './filesSideNavAdd';
import FilesSideNavEdit from './filesSideNavEdit';
import FilesSideNavView from './filesSideNavView';

const RecentFiles = () => {

    const [tabSelection, setTabSelection] = useState('table');
    const [continueChange, setContinueChange] = useState('ViewFile');

    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');

    const columns = [
        {
            name: "File name",
            selector: (row) => row.filename,
            sortable: false,
            reorder: true,
        },
        {
            name: "",
            selector: (row) => row.action,
            sortable: false,
            reorder: true,
        },

    ];
    const data = [
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span><span><img src={deleteIcon} /></span></div>

        },
    ]

    const handlerOpenViewFile = () => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setContinueChange('ViewFileStep');
        document.body.classList.add('no-scroll');


    }
    const handlerNewSideNave = () => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setContinueChange('createStep');
        document.body.classList.add('no-scroll');


    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');


    }
    const handlerChangeForStep = () => {
        if (continueChange === 'ViewFileStep') {
            setContinueChange('EditFileStep');
        }
    }



    return (
        <div className="recentFilesTable mt-3">    
    <DataTable
      columns={columns}
      data={data}
      defaultSortFieldId={2}
      selectableRows={false}
      selectableRowsNoSelectAll={false}
      selectableRowsVisibleOnly={false}
      selectableRowsHighlight={false}
      selectableRowsSingle={false}
      expandableRows={false}
      expandOnRowClicked={false}
      expandOnRowDoubleClicked={false}
      expandableRowsHideExpander={false}
      pagination={false}
      highlightOnHover={true}
      onRowClicked={handlerOpenViewFile}
      striped={false}
      pointerOnHover={false}
      dense={false}
      persistTableHead={true}
      noHeader={false}
      fixedHeader={false}
      fixedHeaderScrollHeight={"300px"}
      progressPending={false}
      noTableHead={false}
      noContextMenu={false}
      direction={Direction.AUTO}
      subHeader={false}
      subHeaderAlign={Alignment.RIGHT}
      subHeaderWrap={true}
      responsive={true}
      disabled={false}
    />


<div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

<div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

{continueChange === 'createStep' ? <FilesSideNavAdd  /> : continueChange === 'ViewFileStep' ? <FilesSideNavView /> : <FilesSideNavEdit />}


    <div className='side-footer'>

        <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

    
        <button className='footer-button-primary ' onClick={handlerChangeForStep}>  {continueChange === 'createStep' ? "Save" : continueChange === 'ViewFileStep' ? "Edit" : "Save"}</button>
                        {continueChange === 'createStep' || continueChange === 'ViewFileStep' ? '' : <button className='footer-button-delete footer-button-back' >Delete</button>}

    </div>

</div>


</div>

    );
}

export default RecentFiles;
