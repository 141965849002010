import React, { useState } from "react";
import MainSidebar from "../sidebar/mainSidebar";
import Footer from "../footer";
import HeaderMain from "../header";
import { Link, Navigate } from "react-router-dom";
import swtichIcon from '../../../seller/assets/icons/refresh-cw.svg'
const DashLayouts = (props) => {
  const { childComp } = props;
  const [openSideBar, setOpenSideBar] = useState('close');


  const handlerSideBar = (val) => {
    setOpenSideBar(val)
  }
  const handlercolseSidebar = () => {
    setOpenSideBar('close');
    document.body.classList.remove('no-scroll');

  }
  const handlerCloseSidebarFromClick = (val) => {
    setOpenSideBar(val)
  }


  const token = localStorage.getItem('token');
  if (!token) {
      // Not authenticated
      return <Navigate to="/login" />;
    }
  return (
    <div className="container-scroller">
      <HeaderMain stateSide={openSideBar} handlerSideBar={handlerSideBar} />
      <div className="page-body-wrapper">
        {openSideBar === 'open' ? <div onClick={handlercolseSidebar} data-toggle="offcanvas" className="mainSidebar sidenav-overlay" /> : ''}
        <MainSidebar stateSide={openSideBar} handlerCloseSidebarFromClick={handlerCloseSidebarFromClick} />
        <div className="main-panel">
          <div className="reminder-top-button homeOwnerRole">
            <Link to='/seller/overview' className='seller-switch-role'>
              <img src={swtichIcon} alt='switch-icon' /> Switch to <b>Affiliate Role</b>
            </Link>
          </div>
          {childComp}
        </div>

      </div>
      <Footer />
    </div>
  );
};

export default DashLayouts;
