import React, { useEffect, useState } from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import moment from 'moment';
import ReminderSideNavEdit from './reminderSideNavEdit';
import { get_event_lists } from '../../../axiosCalls/userdashboard';

const generateEvents = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
    const events = [];

    
    

    // Loop through months, starting from the current month
    for (let month = currentMonth; month < currentMonth + 12; month++) {
        const firstDayOfMonth = new Date(currentYear, month, 1);
        const monthName = firstDayOfMonth.toLocaleString('default', { month: 'long' });
        const monthEvents = [];

        // If it's not the last month, add an event with a reminder date
        if (month !== currentMonth + 11) {
            monthEvents.push({
                id: month + 1,
                category: <span className="type-red reminders-badge">Home Essential</span>,
                description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse velit esse voluptate velit esse  esse velit esse voluptate velit esse ',
                type: 'red',
                reminder: moment(firstDayOfMonth).add(24, 'days').toDate(), // Add 24 days to the first day of the month
                name: 'Cleaning'
            });
        }

        events.push({
            month: monthName,
            events: monthEvents
        });
    }

    return events;
};

const RemindersTable = () => {
    useEffect(() => {
      
        const geteventlists = async ()=>{
            const res = await get_event_lists();
        }
        geteventlists();
    
    }, [])
    const columns = [
        {
            name: "Reminder",
            selector: (row) => row.reminder,
            sortable: false,
            cell: row => row.reminder instanceof Date ? <div className='date-view'>{moment(row.reminder).format('DD ddd')}</div> : <div className='month-view'>{row.month}</div>
        },
        {
            name: "",
            selector: (row) => row.description,
            sortable: false
        },
        {
            name: "Category",
            selector: (row) => row.category,
            sortable: false
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: false
        }
    ];

    const events = generateEvents();

    // Flatten events into rows
    const rows = events.flatMap(event => [
        { month: event.month, isMonthRow: true }, // Display month name as a separate row
        ...event.events // Display the events for that month
    ]);
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');

    const handleRowSideNavOpen = () => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');


    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');



    }

    return (
        <div>
            <div className='reminder-table'>
                <DataTable
                    columns={columns}
                    data={rows}
                    defaultSortFieldId={2}
                    selectableRows={false}
                    selectableRowsNoSelectAll={false}
                    selectableRowsVisibleOnly={false}
                    selectableRowsHighlight={false}
                    selectableRowsSingle={false}
                    expandableRows={false}
                    onRowClicked={handleRowSideNavOpen} // Attach click event handler
                    expandOnRowClicked={false}
                    expandOnRowDoubleClicked={false}
                    expandableRowsHideExpander={false}
                    pagination={true}
                    highlightOnHover={true}
                    striped={false}
                    pointerOnHover={false}
                    dense={false}
                    persistTableHead={true}
                    noHeader={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight={"300px"}
                    progressPending={false}
                    noTableHead={false}
                    noContextMenu={false}
                    direction={Direction.AUTO}
                    subHeader={false}
                    subHeaderAlign={Alignment.LEFT}
                    subHeaderWrap={true}
                    responsive={true}
                    disabled={false}
                />
            </div>
            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                <ReminderSideNavEdit />


                <div className='side-footer'>

                    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

                    <button className='footer-button-primary ' >Save</button>


                </div>

            </div>
        </div>
    );
};

export default RemindersTable;
