import React, { useState } from 'react';
import warningIcon from "../asstes/dash-icons/warningIcon.png";
import ProjectsSideNav from '../components/inc/projectsSideNav';
import ProjectsSideNavNew from '../components/inc/projectsSideNavNew';
import { Form } from 'react-bootstrap';
import ProjectSideNavEdit from '../components/inc/projectSideNavEdit';


const Projects = () => {
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('createStep');
    const [valueForView, setValueForView] = useState([]);
    const handlerReminderSideNave = () => {
        setContinueChange('createStep');
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');

    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');


    }
    const handlerChangeStep = () => {

             setContinueChange('EditStep')
            
 
    }
    const projetcsList = [
        {
            id: 1,
            projectTitle: "Et diam",
            amount: "430.40",
            listOfServices: [
                {
                    id: 1,
                    title: 'Home Essential',
                    type: "red",
                },
                {
                    id: 2,
                    title: 'Other',
                    type: "green",
                },
                {
                    id: 3,
                    title: 'Service',
                    type: "green",
                },
                {
                    id: 4,
                    title: 'Home Essential',
                    type: "blue",
                },
                {
                    id: 5,
                    title: 'Other',
                    type: "camel",
                },
            ]
        },
        {
            id: 1,
            projectTitle: "Et diam",
            amount: "430.40",
            listOfServices: [
                {
                    id: 1,
                    title: 'Home Essential',
                    type: "red",
                },
                {
                    id: 2,
                    title: 'Other',
                    type: "green",
                },
                {
                    id: 3,
                    title: 'Service',
                    type: "green",
                },
                {
                    id: 4,
                    title: 'Home Essential',
                    type: "blue",
                },
                {
                    id: 5,
                    title: 'Other',
                    type: "camel",
                },
            ]
        },
        {
            id: 1,
            projectTitle: "Et diam",
            amount: "430.40",
            listOfServices: [
                {
                    id: 1,
                    title: 'Home Essential',
                    type: "red",
                },
                {
                    id: 2,
                    title: 'Other',
                    type: "green",
                },
                {
                    id: 3,
                    title: 'Service',
                    type: "green",
                },
                {
                    id: 4,
                    title: 'Home Essential',
                    type: "blue",
                },
                {
                    id: 5,
                    title: 'Other',
                    type: "camel",
                },
            ]
        },
        {
            id: 1,
            projectTitle: "Et diam",
            amount: "430.40",
            listOfServices: [
                {
                    id: 1,
                    title: 'Home Essential',
                    type: "red",
                },
                {
                    id: 2,
                    title: 'Other',
                    type: "green",
                },
                {
                    id: 3,
                    title: 'Service',
                    type: "green",
                },
                {
                    id: 4,
                    title: 'Home Essential',
                    type: "blue",
                },
                {
                    id: 5,
                    title: 'Other',
                    type: "camel",
                },
            ]
        },
        {
            id: 1,
            projectTitle: "Et diam",
            amount: "430.40",
            listOfServices: [
                {
                    id: 1,
                    title: 'Home Essential',
                    type: "red",
                },
                {
                    id: 2,
                    title: 'Other',
                    type: "green",
                },
                {
                    id: 3,
                    title: 'Service',
                    type: "green",
                },
                {
                    id: 4,
                    title: 'Home Essential',
                    type: "blue",
                },
                {
                    id: 5,
                    title: 'Other',
                    type: "camel",
                },
            ]
        },
        {
            id: 1,
            projectTitle: "Et diam",
            amount: "430.40",
            listOfServices: [
                {
                    id: 1,
                    title: 'Home Essential',
                    type: "red",
                },
                {
                    id: 2,
                    title: 'Other',
                    type: "green",
                },
                {
                    id: 3,
                    title: 'Service',
                    type: "green",
                },
                {
                    id: 4,
                    title: 'Home Essential',
                    type: "blue",
                },
                {
                    id: 5,
                    title: 'Other',
                    type: "camel",
                },
            ]
        },

    ];
    const HandlerViewProjetc = (id, title) => {
        const values = [{
            id: id,
            title: title
        }]
        setValueForView(values);
        setContinueChange('suggets');

        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');

    }

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <h5 className="mb-2 text-titlecase mb-4">Projects</h5>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">

                            <div className=" top-text-head">

                                <div className="reminder-top-button">

                                    <div className='sortble-sec-projects'>
                                        <div>Sort by</div>
                                        <Form.Select>
                                            <option value='Last added'>Last added</option>
                                            <option value='Date'>Date</option>
                                        </Form.Select>

                                    </div>

                                    <button className="filled-btn" onClick={handlerReminderSideNave}>

                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673" stroke="white" strokeLinecap="round" />
                                        </svg>

                                        Add Project</button>

                                </div>

                            </div>
                        </div>
                        <div className="row paddingRight0">
                            {projetcsList.map((pro, index) => {
                                return (
                                    <div className="col-lg-3 col-md-4  col-sm-6 mb-3 item_each_home_cards" id={index} key={index} onClick={(e) => HandlerViewProjetc(index, pro.projectTitle)}>
                                        <div className="dashboard-card">
                                            <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                                <div className=" justify-content-between align-items-center ">
                                                    <div className="icon-box-card">

                                                    </div>

                                                    <p className="projects dashboard-heading">{pro.projectTitle}</p>
                                                    <p className="dashboard-subheading row ProjectService">
                                                        {pro.listOfServices.map((ser, Childindex) => {
                                                            return (
                                                                <span class={"childProjectService services-badge  type-" + ser.type} key={Childindex}>{ser.title}</span>
                                                            )
                                                        })}

                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-footer'>
                                            Project Cost: ${pro.amount}
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                {continueChange === 'suggets' ? <ProjectsSideNav propsValue={valueForView} /> : continueChange === 'createStep' ? <ProjectsSideNavNew /> : <ProjectSideNavEdit />}


                <div className='side-footer'>

                    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

                    <button className='footer-button-primary ' onClick={handlerChangeStep}>{continueChange !== 'suggets' ? 'Save' : 'Edit'}</button>


                </div>

            </div>

        </div>
    );
}

export default Projects;
