import React, { useState } from "react";
import ReminderSideNavEdit from "./reminderSideNavEdit";
import ReminderSideNavView from "./reminderSideNavView";

const DashboardCalender = () => {
  const [sideNavWidth, setSideNavWidth] = useState('0px');
  const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
  const [continueChange, setContinueChange] = useState('viewStep');


  const handlerReminderSideNave = () => {
    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    document.body.classList.add('no-scroll');
    setContinueChange('viewStep');

  };

  const handlerCancelSideNav = () => {
    setSideNavWidth('sidenavPopuWidthHide');
    setsideNavWidthOverlay('0%');
    document.body.classList.remove('no-scroll');
    setContinueChange('viewStep');

  };

  const handlerChangeToEdit = () => {
    setContinueChange('editStep');
  }

  // Get the current date
  const currentDate = new Date();

  // Calculate the start and end dates for the current week
  const currentWeekStart = new Date(currentDate);
  const currentDay = currentDate.getDay();
  const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
  currentWeekStart.setDate(currentDate.getDate() + diffToMonday); // Set to the first day of the current week (Monday)
  const currentWeekEnd = new Date(currentWeekStart);
  currentWeekEnd.setDate(currentWeekStart.getDate() + 6); // Set to the last day of the current week (Sunday)

  // Calculate the start and end dates for the next week
  const nextWeekStart = new Date(currentWeekEnd);
  nextWeekStart.setDate(currentWeekEnd.getDate() + 1); // Set to the first day of the next week (Monday)
  const nextWeekEnd = new Date(nextWeekStart);
  nextWeekEnd.setDate(nextWeekStart.getDate() + 6); // Set to the last day of the next week (Sunday)

  // Function to generate an array of dates between two dates
  const getDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  // Generate arrays of dates for the current week and next week
  const currentWeekDates = getDatesBetween(currentWeekStart, currentWeekEnd);
  const nextWeekDates = getDatesBetween(nextWeekStart, nextWeekEnd);

  // Combine current week and next week dates into one array
  const allWeekDates = [...currentWeekDates, ...nextWeekDates];

  // Sample array of events
  const events = [
    { id: 1, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'red', date: new Date(2024, 4, 24) },
    { id: 2, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'green', date: new Date(2024, 2, 26) },
    { id: 3, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'purple', date: new Date(2024, 2, 26) },
    { id: 4, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'blue', date: new Date(2024, 2, 30) },
    { id: 5, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'green', date: new Date(2024, 3, 1) },
    { id: 6, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'camel', date: new Date(2024, 2, 31) },
    { id: 7, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'red', date: new Date(2024, 3, 4) },
    { id: 8, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'purple', date: new Date(2024, 3, 10) },
  ];

  // Function to filter events for a specific date
  const getEventsForDate = (date) => {
    return events.filter(event => event.date.toDateString() === date.toDateString());
  };

  return (
    <div>
      <div className="parent-upcomingDateRow">
        <div className="row upcomingDateRow">
          {allWeekDates.map((date, index) => (
            <div className={`dayBox ${date.toDateString() === currentDate.toDateString() ? 'current-day' : ''}`} key={index}>
              <div className="customDates">
                <span> {date.toLocaleString('default', { month: 'short', day: '2-digit' })}</span> {/* Display month and day */}
                <span className="day">{date.toLocaleString('en-us', { weekday: 'short' })}</span> {/* Display day of the week */}
              </div>
              <div className="event-list">
                {getEventsForDate(date).map(event => (
                  <div onClick={() => handlerReminderSideNave(event)} className="parent-event-title" key={event.id}>
                    <div className={"event-calender-title type-" + event.type}>{event.title}</div>{" "}
                    <div className={"event-calender-des type-sub-" + event.type}>
                      {event.description}
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="event_count">
                <a href="#">    Events: {" "}
                  {getEventsForDate(date).length}</a>
              </div> */}
            </div>
          ))}
        </div>
      </div>
      {/* <div className="parent-upcomingDateRow">
        <div className="upcomingDateRow">

        </div>
      </div> */}
      <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

      <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
     
      {continueChange === 'viewStep' ? <ReminderSideNavView /> : <ReminderSideNavEdit /> } 

        <div className='side-footer'>
          <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>
           

          {continueChange === 'viewStep' ? <button className='footer-button-primary' onClick={handlerChangeToEdit}>Edit</button>:<><button className='footer-button-primary' >Save</button><button className='footer-button-delete footer-button-back'>Delete</button></>}

        </div>
      </div>
    </div>
  );
};

export default DashboardCalender;
