import React, { useState } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TagsInput from './TagsInput';
import { Select } from './Select';
import { Link } from 'react-router-dom';
import LinkReminders from './linkReminders';


const HomeEssentialsSideNav = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [warrantyCheck, setWarrantyCheck] = useState('unchecked');

    const handlerWarrantyChecked = () => {

        if (warrantyCheck === 'unchecked') {
            setWarrantyCheck('checked');
        } else {
            setWarrantyCheck('unchecked');

        }

    }

    // const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5', 'Option 6', 'Option 7', 'Option 8', 'Option 9', 'Option 10'];

    const options = [
        { label: "Home Essential", value: 1 },
        { label: "Home Essential", value: 2 },
        { label: "Home Essential", value: 3 },
        { label: "Home Essential", value: 4 },
        { label: "Home Essential", value: 5 },
    ]
    const [value1, setValue1] = useState([]);
    const [value2, setValue2] = useState(options[0]);

    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>Add Home Essential</h3>
                <h5></h5>

            </div>
            <div className='side-body'>
                <Form>
                    <Row className="mb-3">

                        <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Home Essential Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                        <Form.Group as={Col} md="12">
                            <Form.Label>Type</Form.Label>
                            <Form.Select>
                                <option value='GoodmanG350'>Goodman G350</option>

                            </Form.Select>


                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="6" className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Make <span className='requiredStar'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="e.g. 2012" />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Model Number <span className='requiredStar'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="e.g. 4689-65498-4646" />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Purchase Date </Form.Label>
                            <Form.Control type="date" value={startDate} placeholder="Name" />

                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Age of Home Essential</Form.Label>
                            <Form.Control type="date" value={startDate} placeholder="Name" />

                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group md="12" className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Linked Reminders</Form.Label>
                                <LinkReminders />
                            </div>



                        </Form.Group>

                    </Row>

                    <Row>
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Files</div>

                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                        </svg>
                                        Add file
                                        <input type='file' className='chooeseFile' />
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Images</div>

                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                        </svg>
                                        Add image
                                        <input type='file' className='chooeseFile' />
                                    </div>
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3" controlId="">
                            <Form.Label>Linked services</Form.Label>

                            <Select
                                multiple
                                options={options}
                                value={value1}
                                onChange={o => setValue1(o)}
                            />
                        </Form.Group>


                        {/* <TagsInput options={options} /> */}
                        <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder='Type here...' />
                        </Form.Group>

                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen warrantyAddArea'>
                                <div className='title'>Warranty</div>

                                <div className='button'>
                                    <label className="switch">
                                        <input type="checkbox" onChange={handlerWarrantyChecked} />
                                        <span className="slider round"></span>
                                    </label>

                                </div>

                            </div>
                            <div className='warrantyForm'>
                                {warrantyCheck === 'checked' ?
                                    <div className='warrantyAddInnerArea'>
                                        <Row className="mb-3">

                                            <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" placeholder="Name" />
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" className="mb-3">
                                                <Form.Label>Number</Form.Label>
                                                <Form.Control type="text" placeholder="Number" />


                                            </Form.Group>

                                            <Form.Group md="12" className='mb-3'>
                                                <div className='files-choosen'>
                                                    <div className='title'>Files</div>

                                                    <div className='button'>
                                                        <div className="outline-yellow-button">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                                            </svg>
                                                            Add file
                                                            <input type='file' className='chooeseFile' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Start </Form.Label>
                                                <Form.Control type="date" value={startDate} placeholder="Name" />

                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>End</Form.Label>
                                                <Form.Control type="date" value={startDate} placeholder="Name" />

                                            </Form.Group>
                                        </Row>
                                        <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control as="textarea" rows={3} placeholder='Type here...' />
                                        </Form.Group>

                                    </div>
                                    : ''}
                            </div>


                        </Form.Group>


                    </Row>



                </Form>
            </div>

        </div>
    );
}

export default HomeEssentialsSideNav;
