import React, { useState } from "react";

import "../asstes/styles/dashboardStyles.css";
import { Link } from "react-router-dom";
import warningIcon from "../asstes/dash-icons/warningIcon.png";
import icon1 from "../asstes/dash-icons/sidebar/dashIcon1.svg";
import icon2 from "../asstes/dash-icons/sidebar/dashIcon2.svg";
import icon3 from "../asstes/dash-icons/sidebar/dashIcon3.svg";
import icon4 from "../asstes/dash-icons/sidebar/dashIcon4.svg";
import icon5 from "../asstes/dash-icons/sidebar/dashIcon5.svg";
import DashboardCalender from "../components/inc/dashboardCalender";
import RecentPurchases from "../components/inc/recentPurchases";
import RecentFiles from "../components/inc/recentFiles";
import ProjectsSideNav from "../components/inc/projectsSideNav";
import ProjectsSideNavNew from "../components/inc/projectsSideNavNew";
import ProjectSideNavEdit from "../components/inc/projectSideNavEdit";
import HomeEssentialsSideNav from "../components/inc/homeEssentialsSideNav";
import ReminderSideNavCreate from "../components/inc/ReminderSideNavCreate";
import PurchasesServicesNavAdd from "../components/inc/purchasesServicesNavAdd";
import FilesSideNavAdd from "../components/inc/filesSideNavAdd";
import HomeEssentialsSideNavView from "../components/inc/homeEssentialsSideNavView";
import HomeEssentialsSideNavEdit from "../components/inc/homeEssentialsSideNavEdit";
import ReminderSideNav from "../components/inc/reminderSideNav";

const Dashboard = () => {


  const [sideNavWidth, setSideNavWidth] = useState('sidenavPopuWidthHide');
  const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
  const [continueChange, setContinueChange] = useState('createStep');
  const [valueForView, setValueForView] = useState([]);


  const HandlerViewProjetc = (id, title) => {
    const values = [{
      id: id,
      title: title
    }]
    setValueForView(values);
    setContinueChange('suggets');

    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    document.body.classList.add('no-scroll');

  }
  const handlerCancelSideNav = () => {
    setSideNavWidth('sidenavPopuWidthHide');
    setsideNavWidthOverlay('0%');
    document.body.classList.remove('no-scroll');


  }
  const handlerChangeStep = () => {


    if (continueChange === 'viewStepHome') {

      setContinueChange('EditStepHome');
    } else if (continueChange === 'suggets') {
      setContinueChange('EditStep');
    }


  }

  const handlerHomeEssentialSideNave = () => {
    setContinueChange('createStepHome');
    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    document.body.classList.add('no-scroll');

  }

  const handlerProjectsSideNave = () => {
    setContinueChange('createStepProject');
    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    document.body.classList.add('no-scroll');

  }
  const handlerRemindersSideNave = () => {
    setContinueChange('createStepRemind');
    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    document.body.classList.add('no-scroll');

  }
  const handlerPServicesSideNave = () => {
    setContinueChange('createStepPServices');
    setIsChecked(false);

    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    document.body.classList.add('no-scroll');

  }
  const handlerFilesSideNave = () => {
    setContinueChange('createStepFiles');
    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    document.body.classList.add('no-scroll');

  }

  function HandlerViewHomeEsstional(id, title) {

    const values = [{
      id: id,
      title: title
    }]
    setValueForView(values);
    setContinueChange('viewStepHome');

    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    document.body.classList.add('no-scroll');


  }
  const [isChecked, setIsChecked] = useState(false); // State to hold checkbox value

  const handleCheckboxChangeOLD = (isChecked) => {
    setIsChecked(isChecked);
};


  const homeEssentials = {
    items: [
      {
        icon: icon1,
        heading: "Pretium",
        subheading: "Goodman G3300",
        warning: false,
      },
      {
        icon: icon2,
        heading: "Air Condicioner",
        subheading: "Goodman G3300",
        warning: true,
      },
      {
        icon: icon3,
        heading: "Ipsum",
        subheading: "Goodman G3300",
        warning: true,
      },
      {
        icon: icon4,
        heading: "Printer",
        subheading: "Goodman G3300",
        warning: false,
      },
      {
        icon: icon5,
        heading: "Mattis",
        subheading: "Goodman G3300",
        warning: false,
      },
      {
        icon: icon3,
        heading: "Ipsum",
        subheading: "Goodman G3300",
        warning: true,
      },
    ],
  };
  const projetcsList = [
    {
      id: 1,
      projectTitle: "Et diam",
      amount: "430.40",
      listOfServices: [
        {
          id: 1,
          title: 'Home Essential',
          type: "red",
        },
        {
          id: 2,
          title: 'Other',
          type: "green",
        }, {
          id: 4,
          title: 'Home Essential',
          type: "blue",
        },
        {
          id: 3,
          title: 'Service',
          type: "green",
        },

        {
          id: 5,
          title: 'Other',
          type: "camel",
        },
      ]
    },
    {
      id: 1,
      projectTitle: "Et diam",
      amount: "430.40",
      listOfServices: [
        {
          id: 1,
          title: 'Home Essential',
          type: "red",
        },
        {
          id: 2,
          title: 'Other',
          type: "green",
        },
        {
          id: 3,
          title: 'Service',
          type: "green",
        },
        {
          id: 4,
          title: 'Home Essential',
          type: "blue",
        },
        {
          id: 5,
          title: 'Other',
          type: "camel",
        },
      ]
    },
    {
      id: 1,
      projectTitle: "Et diam",
      amount: "430.40",
      listOfServices: [
        {
          id: 1,
          title: 'Home Essential',
          type: "red",
        },
        {
          id: 2,
          title: 'Other',
          type: "green",
        },
        {
          id: 3,
          title: 'Service',
          type: "green",
        },
        {
          id: 4,
          title: 'Home Essential',
          type: "blue",
        },
        {
          id: 5,
          title: 'Other',
          type: "camel",
        },
      ]
    },
    {
      id: 1,
      projectTitle: "Et diam",
      amount: "430.40",
      listOfServices: [
        {
          id: 1,
          title: 'Home Essential',
          type: "red",
        },
        {
          id: 2,
          title: 'Other',
          type: "green",
        },
        {
          id: 3,
          title: 'Service',
          type: "green",
        },
        {
          id: 4,
          title: 'Home Essential',
          type: "blue",
        },
        {
          id: 5,
          title: 'Other',
          type: "camel",
        },
      ]
    },


  ];


  return (
    <>
      {/* <!-- partial --> */}

      <div className="content-wrapper">
        <div className="row">
          <div className="col-xl-12 grid-margin stretch-card flex-column">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 dashboardHeadings">
                <h5 className="mb-2 text-titlecase mb-4">My sweet home</h5>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 d-flex text-top-subtext dashboardHeadings">
                <div className="col-md-4 top-text-head">
                  Address:
                  <span className="top-text-normal">
                    1901 Thornridge Cir. Shiloh, Hawaii 81063
                  </span>
                </div>
                <div className="col-md-4 top-text-head">
                  Category:
                  <span className="top-text-normal">Primary Residence</span>
                </div>
                <div className="col-md-4 top-text-head">
                  Type:
                  <span className="top-text-normal">Primary Residence</span>
                </div>
              </div>
            </div>

            {/* Home Essentials Board */}

            <div className="row  dashboard-card-parent">
              <div className="row card-actions-btns">
                <div className="col-md-6 col-sm-6">
                  <h2 className="card-heading">Home Essentials</h2>
                </div>
                <div className="col-md-6 col-sm-6 card-right-action">
                  <Link to='/home-essentials' className="outline-yellow-button">View All</Link>
                  <Link onClick={handlerHomeEssentialSideNave} className="outline-primary-button">
                    Add
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                        stroke="#00487C"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="row">
                {homeEssentials.items.map((card, index) => {
                  return (
                    <div className="col-lg-2 col-md-4 col-sm-6 item_each_home_cards" id={index} key={index} onClick={(e) => HandlerViewHomeEsstional(index, card.heading)}>
                      <div className="dashboard-card">
                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                          <div className=" justify-content-between align-items-center ">
                            <div className="icon-box-card">
                              <img src={card.icon} />
                              {card.warning ? <div className="card-warining"><img src={warningIcon} />WARINING</div> : ''}

                            </div>
                            <p className="dashboard-heading">{card.heading}</p>
                            <p className="dashboard-subheading">
                              {card.subheading}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>





            {/* caldender board  */}
            <div className="row  dashboard-card-parent">
              <div className="row card-actions-btns">
                <div className="col-md-6">
                  <h2 className="card-heading">Upcoming Reminders</h2>
                </div>
                <div className="col-md-6 card-right-action">
                  <Link to='/reminders' className="outline-yellow-button">View All</Link>
                  <Link onClick={handlerRemindersSideNave} className="outline-primary-button">
                    Add
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                        stroke="#00487C"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="row">

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <DashboardCalender />
                </div>
              </div>
            </div>

            <div className="row  dashboard-card-parent">
              <div className="row card-actions-btns">
                <div className="col-md-6 col-sm-6">
                  <h2 className="card-heading">Projects</h2>
                </div>
                <div className="col-md-6 col-sm-6 card-right-action">
                  <Link to='/projects' className="outline-yellow-button">View All</Link>
                  <Link onClick={handlerProjectsSideNave} className="outline-primary-button">
                    Add
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                        stroke="#00487C"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="row paddingRight0">
                {projetcsList.map((pro, index) => {
                  return (
                    <div className="col-lg-3 mb-3 col-md-6 col-sm-6 item_each_home_cards" id={index} key={index} onClick={(e) => HandlerViewProjetc(index, pro.projectTitle)}>
                      <div className="dashboard-card">
                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                          <div className=" justify-content-between align-items-center ">
                            <div className="icon-box-card">

                            </div>

                            <p className="projects dashboard-heading">{pro.projectTitle}</p>
                            <p className="dashboard-subheading row ProjectService">
                              {pro.listOfServices.map((ser, Childindex) => {
                                return (
                                  <span class={"childProjectService services-badge  type-" + ser.type} key={Childindex}>{ser.title}</span>
                                )
                              })}

                            </p>

                          </div>
                        </div>
                      </div>
                      <div className='card-footer'>
                        Project Cost: ${pro.amount}
                      </div>
                    </div>
                  );
                })}

              </div>
            </div>

            <div className="row dashboard-parent-recents">
              {/* Recent Purchases */}
              <div className=" col-md-6 dashboard-card-parent recentPurchasesMobile">
                <div className="row card-actions-btns">
                  <div className="col-md-6">
                    <h2 className="card-heading">Recent Purchases & Services</h2>
                  </div>
                  <div className="col-md-6 card-right-action">
                    <Link to='/purchases-services' className="outline-yellow-button">View All</Link>
                    <Link onClick={handlerPServicesSideNave} className="outline-primary-button">
                      Add
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                          stroke="#00487C"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>

                <div className="row p-0">

                  <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                    <RecentPurchases />
                  </div>
                </div>
              </div>
              {/* Recent files */}
              <div className=" col-md-6 dashboard-card-parent recent_files-action">
                <div className="row card-actions-btns">
                  <div className="col-md-6">
                    <h2 className="card-heading">Recent Files</h2>
                  </div>
                  <div className="col-md-6 card-right-action">
                    <Link to='/files' className="outline-yellow-button">View All</Link>
                    <Link onClick={handlerFilesSideNave} className="outline-primary-button">
                      Add
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                          stroke="#00487C"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="row p-0">

                  <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                    <RecentFiles />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>
{isChecked ? 
  <div id="mySidenav"  className={`sidenav ${sideNavWidth}`}>

 
   <HomeEssentialsSideNav />  


  <div className='side-footer'>
    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>
    <button className='footer-button-primary ' onClick={handlerChangeStep}>{continueChange.includes('Edit') ? 'Save' : continueChange.includes('create') ? 'Save' : 'Edit'}</button>

    {continueChange.includes('Edit') ? <button className='footer-button-delete footer-button-back' >Delete</button> : ''}
  </div>

</div>

:
  <div id="mySidenav"  className={`sidenav ${sideNavWidth}`}>

  {continueChange === 'suggets' ? <ProjectsSideNav propsValue={valueForView} /> : continueChange === 'EditStep' ? <ProjectSideNavEdit /> : ''}

  {continueChange === 'createStepHome' ? <HomeEssentialsSideNav /> : ''}
  {continueChange === 'createStepProject' ? <ProjectsSideNavNew /> : ''}
  {continueChange === 'createStepRemind' ? <ReminderSideNav /> : ''}

  {continueChange === 'createStepPServices' ? <PurchasesServicesNavAdd handleCheckboxChangeOLD={handleCheckboxChangeOLD} /> : ''}

  {continueChange === 'createStepFiles' ? <FilesSideNavAdd /> : ''}
  {continueChange === 'viewStepHome' ? <HomeEssentialsSideNavView propsValue={valueForView} /> : ''}
  {continueChange === 'EditStepHome' ? <HomeEssentialsSideNavEdit propsValue={valueForView} /> : ''}


  <div className='side-footer'>
    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>
    <button className='footer-button-primary ' onClick={handlerChangeStep}>{continueChange.includes('Edit') ? 'Save' : continueChange.includes('create') ? 'Save' : 'Edit'}</button>

    {continueChange.includes('Edit') ? <button className='footer-button-delete footer-button-back' >Delete</button> : ''}
  </div>

</div>
}
      





      </div>
    </>
  );
};

export default Dashboard;
