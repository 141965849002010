import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Select } from './Select';

const ReminderSideNavEdit = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [value1, setValue1] = useState([ { label: "Home Essential", value: 1 },]);

    const options = [
        { label: "Home Essential", value: 1 },
        { label: "Home Essential", value: 2 },
        { label: "Home Essential", value: 3 },
        { label: "Home Essential", value: 4 },
        { label: "Home Essential", value: 5 },
    ]


     return (
        <div style={{ width: "100%" }}>

            <div className='side-header'>
                <h3>Edit Reminder</h3>

            </div>
            <div className='side-body CraeteReminderSideBody mt-3'>
            <Form>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Name" defaultValue="Home Essential" />
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control as="textarea" rows={3} placeholder='Description' defaultValue="Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="6" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="date" value='2024-08-02' placeholder="Name" />

                                    </Form.Group>

                                    <Form.Group as={Col} md="6" className="mb-3" >
                                        <Form.Label>Repeats</Form.Label>
                                        <Form.Select>
                                            <option value='never'>Never</option>
                                            <option value='EveryDay'>Every Day</option>
                                            <option selected value='EveryWeek'>Every Week</option>
                                            <option value='EveryMonth'>Every Month</option>
                                            <option value='EveryYear'>Every Year</option>
                                            <option value='Custom'>Custom</option>
                                        </Form.Select>


                                    </Form.Group>

                                    <Form.Group as={Col} md="12" className="mb-3" controlId="">
                                        <Form.Label>Linked Home Essentials</Form.Label>

                                        <Select
                                            multiple
                                            options={options}
                                            value={value1}
                                            onChange={o => setValue1(o)}
                                        />
                                    </Form.Group>
 

                                </Row>



                            </Form>
            </div>
        </div>
    );
}

export default ReminderSideNavEdit;
