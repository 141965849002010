import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import giftIconDash from '../assets/icons/giftIconDash.svg'
import sellerGiftIcon from '../assets/icons/seller-gift-icon.svg'
import swtichIcon from '../assets/icons/refresh-cw.svg';
import whiteGift from '../assets/icons/whiteGift.svg'
import saveIcon from '../assets/icons/saveIcon.svg'
import historyIcon from '../assets/icons/historyIcon.svg'
import { Form } from 'react-bootstrap';
import RecentlyGiftedSubscriptions from '../inc/RecentlyGiftedSubscriptions';
import RecentlyReferred from '../inc/RecentlyReferred';
import ShowCodeHistory from '../inc/ShowCodeHistory';
import refreshcw from '../assets/icons/refresh-cw-blue.svg'
import SelectDropDownYTD from '../inc/selectDropDownYTD';
import GiftNowPopup from '../inc/giftNowPopup';
import ReferNowPopup from '../inc/referNowPopup';



const GiftReferReminders = () => {
    const [giftTabs, setGiftTabs] = useState('giftedSub');
    const [modalShow, setModalShow] = useState(false);
    const [modalShowRefer, setModalShowRefer] = useState(false);

    const handlerClosePopup = (set) =>{
        setModalShow(set)

    }
    const handlerClosePopupRefer = (set) =>{
        setModalShowRefer(set)

    }

 
    const togglePopup = () => {
        setModalShow(true)
     };
 
    const togglePopupRefer = () => {
        setModalShowRefer(true)
     };


    const homeEssentials = {
        items: [
            {
                icon: giftIconDash,
                iconAllow: true,
                heading: "Subscriptions Gifted",
                btnText: 'Gift Now',
                subheading: "26",
                description: 'Number of Subscription gifted',
                dropdown: true

            },
            {
                icon: sellerGiftIcon,
                iconAllow: false,
                heading: "Redeemed Referrals",
                btnText: 'Refer Now',
                subheading: "76",
                description: 'Number of Referral Codes Redeemed',
                dropdown: true
            }

        ],
    };
    const handerSellerGiftTab = (type) => {
        setGiftTabs(type);
    }


    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">

                        <div className=" paddingRight0">

                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <h5 className="mb-2 text-titlecase mb-4">Gift or Refer remind</h5>
                                </div>
                               
                            </div>
                        </div>


                        <div className=" ">

                            <div className="row">
                                {homeEssentials.items.map((card) => {
                                    return (
                                        <div className="col-lg-4 item_each_home_cards">
                                            <div className="dashboard-card">
                                                <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                                    <div className=" justify-content-between align-items-center ">
                                                        <div className="icon-box-card">
                                                            <img src={card.icon} />
                                                            <p className="dashboard-subheading">
                                                                {card.subheading}

                                                            </p>
                                                            {
                                                                card.dropdown ?
                                                                    <div className='seller-card-dropdown'>

                                                                        <SelectDropDownYTD />
                                                                    </div>

                                                                    : ''}
                                                        </div>
                                                        <p className="dashboard-heading">{card.heading}</p>
                                                        <p className="dashboard-description">
                                                            {card.description}
                                                        </p>
                                                        <Link className='seller-card-button' onClick={card.btnText === 'Gift Now' ?  togglePopup : togglePopupRefer} >
                                                            {card.iconAllow && <img src={whiteGift} alt='whiteGift' />}

                                                            {card.btnText}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="col-lg-4 item_each_home_cards lastSellerItem">
                                    <div className="dashboard-card ">
                                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                            <div className=" referralCode mb-2">
                                                <div className="icon-box-card">

                                                </div>
                                                <p className="dashboard-heading">Generate Referral Code
                                                    <div className='refresh-redeemed-code'>
                                                        <img src={refreshcw} alt='refresh' /><span>New Code</span></div>
                                                </p>
                                                <p className="dashboard-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                                <p className='dashboard-card-textfiled-parent'>
                                                    <input type='text' className='dashboard-card-textfiled' />
                                                    <button type='button' className='dashboard-card-submit'> <img src={saveIcon} alt='saveIcon' />Save</button>
                                                </p>


                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='mt-4'>
                                    <div className="row mb-3">
                                        <div className="col-md-6 ">
                                            <div className='referalTabs'>
                                                <h2 onClick={() => handerSellerGiftTab('giftedSub')} className={giftTabs === 'giftedSub' ? ' card-heading tabActive' : 'card-heading'}>Recent Gifted Subscriptions</h2>
                                                <h2 onClick={() => handerSellerGiftTab('recentRefd')} className={giftTabs === 'recentRefd' ? ' card-heading tabActive' : 'card-heading'}>Recent Referral Codes Redeemed</h2>
                                            </div>

                                        </div>
                                        <div className="col-md-6 card-right-action">
                                            <div className='recent-earning-sortlble'>


                                                <div onClick={() => handerSellerGiftTab('history')} className={giftTabs === 'history'? 'tabActive seller-switch-role' :'seller-switch-role'}>
                                                    <img src={historyIcon} alt='switch-icon' /> Show Code History
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {giftTabs === 'giftedSub' ? <RecentlyGiftedSubscriptions /> : giftTabs === 'recentRefd' ? <RecentlyReferred /> : <ShowCodeHistory />}


                                </div>

                            </div>




                        </div>
                    </div>
                </div>
            </div>
           
                    <GiftNowPopup show={modalShow} handlerClosePopup={handlerClosePopup} step="first" onHide={() => setModalShow(false)} />
                    <ReferNowPopup show={modalShowRefer} handlerClosePopupRefer={handlerClosePopupRefer} step="first" onHide={() => setModalShowRefer(false)} />
              
        </div>

    );
}

export default GiftReferReminders;
