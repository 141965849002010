import React, { useState } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import CalendarCheck from '../../asstes/dash-icons/sidebar/CalendarCheck.svg';
import MagnifyingGlass from '../../asstes/dash-icons/sidebar/MagnifyingGlass.svg'

const SearchModel = (props) => {
    const [Modelshow, setShow] = useState(true);

 
    const handlerModelClose = () => {

       }

    return (
        <Modal {...props}  aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton></Modal.Header>

            <Modal.Body className="grid-example">
                
                <Form>
                    <InputGroup className='search-field'>
                        <InputGroup.Text id="basic-addon1"><img src={MagnifyingGlass} /></InputGroup.Text>

                        <Form.Control type='text' placeholder='Search in My sweet house' />
                    </InputGroup>

                </Form>

                <div className='search-result'>

                    <div className='search-area-items'>
                        <span className='heading'>Home Essentials</span>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Pretium</span></div>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Ipsum</span></div>

                    </div>

                    <div className='search-area-items'>
                        <span className='heading'>Upcoming Reminders</span>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Amet adipiscing cursus dui</span></div>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Faucibus luctus eu nisl</span></div>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Cras enim morbi nec</span></div>

                    </div>

                    <div className='search-area-items'>
                        <span className='heading'>Recent Purchases & Services</span>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Aenean elementum enim viverra</span></div>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Tellus risus consectetur tincidunt</span></div>

                    </div>
                    <div className='search-area-items'>
                        <span className='heading'>Projects</span>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Nisl adipiscing mollis donec</span></div>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Facilisis nisi nullam faucibus</span></div>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>In mollis lorem vitae</span></div>

                    </div>

                    <div className='search-area-items'>
                        <span className='heading'>Files</span>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Interdum pretium massa placerat</span></div>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Faucibus rhoncus eget vestibulum</span></div>
                        <div className='icon-list'><span className='icon'><img src={CalendarCheck} /></span><span className='icon-content'>Sed a lectus pharetra</span></div>

                    </div>

                </div>
            </Modal.Body>

        </Modal>
    );
}

export default SearchModel;
