import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import LinkReminders from "./linkReminders";
import ListOfFIlesForEssemtialNav from "./ListOfFIlesForEssemtialNav";

const FilesSideNavView = () =>{

    return(
        <>
         <div className='side-nav'>
                <div className='side-header'>
                    <h3>Files</h3>


                </div>
                <div className='side-body homeEssentialSideNav'>
                    <Form >
                        <Row>
                            

                            <Form.Group as={Col} md="6" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Name</Form.Label>
                                <p className='text-paragraph'> Never</p>

                            </div>

                        </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Upload Date</Form.Label>
                                <p className='text-paragraph'> 01.02.2023</p>

                            </div>


                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>
                                <Form.Label>Attached Files</Form.Label>

                                <ListOfFIlesForEssemtialNav />

                            </div>

                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Custom REminders</Form.Label>
                                <p className='text-paragraph'> Vulputate hac augue tempor facilisi dolor.</p>
 
                            </div>

                        </Form.Group>
                    

                        <Form.Group md="12"  className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Linked Reminders</Form.Label>
                                <LinkReminders />
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Suggested  REminders</Form.Label>
                                <p className='text-paragraph'> Vulputate hac augue tempor facilisi dolor.</p>
                                <p className='text-paragraph'> Integer consectetur tellus ac sed sagittis aenean.</p>

                            </div>

                        </Form.Group>

                        </Row>
                    </Form>

                </div>

            </div>

        </>
        
       
    )

}

export default FilesSideNavView; 