import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import LinkReminders from "./linkReminders";

const ReminderSideNavView = () => {

    return (
        <>

            <div className='side-nav'>
                <div className='side-header'>
                    <h3>Reminders</h3>


                </div>
                <div className='side-body homeEssentialSideNav'>
                    <Form>
                        <Row>
                            <Form.Group md="12" className="mb-3">
                                <div className='link-table-main'>

                                    <Form.Label>Molestie faucibus diam sed pharetra tristique </Form.Label>
                                    <div className='warranty-section'>
                                        <span><b>Name</b> </span>
                                        <span><p>Never</p></span>


                                        <span><b>Description </b></span>
                                        <span><p>Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla. Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla.</p> </span>



                                    </div>

                                </div>



                            </Form.Group>

                            <Form.Group as={Col} md="6" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Repeats</Form.Label>
                                <p className='text-paragraph'> Never</p>

                            </div>

                        </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Date</Form.Label>
                                <p className='text-paragraph'> 01.02.2023</p>

                            </div>

                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Linked Home Essential</Form.Label>
                                <div className='listOfImages'>
                                <span class="services-badge  type-blue">Home Essential</span>
                                <span class="services-badge  type-green">Home Essential</span>
                                <span class="services-badge  type-camel">Home Essential</span>
                                <span class="services-badge  type-red">Home Essential</span>
                                </div>

                            </div>

                        </Form.Group>

                        <Form.Group md="12"  className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Linked Reminders</Form.Label>
                                <LinkReminders />
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main '>
                                <Form.Label>Suggested  REminders</Form.Label>
                                <p className='text-paragraph'> Vulputate hac augue tempor facilisi dolor.</p>
                                <p className='text-paragraph'> Integer consectetur tellus ac sed sagittis aenean.</p>

                            </div>

                        </Form.Group>

                        </Row>
                    </Form>

                </div>

            </div>

        </>
    )


}

export default ReminderSideNavView;