import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const GiftNowPopup = (props) => {

    const [giftSubscriptionPopup, setGiftSubscriptionPopup] = useState(props.step);
    const [selectedOption, setSelectedOption] = useState('card');

    const states = [
        { name: "Alabama", abbreviation: "AL" },
        { name: "Alaska", abbreviation: "AK" },
        { name: "Arizona", abbreviation: "AZ" },
        { name: "Arkansas", abbreviation: "AR" },
        { name: "California", abbreviation: "CA" },
        { name: "Colorado", abbreviation: "CO" },
        { name: "Connecticut", abbreviation: "CT" },
        { name: "Delaware", abbreviation: "DE" },
        { name: "Florida", abbreviation: "FL" },
        { name: "Georgia", abbreviation: "GA" },
        { name: "Hawaii", abbreviation: "HI" },
        { name: "Idaho", abbreviation: "ID" },
        { name: "Illinois", abbreviation: "IL" },
        { name: "Indiana", abbreviation: "IN" },
        { name: "Iowa", abbreviation: "IA" },
        { name: "Kansas", abbreviation: "KS" },
        { name: "Kentucky", abbreviation: "KY" },
        { name: "Louisiana", abbreviation: "LA" },
        { name: "Maine", abbreviation: "ME" },
        { name: "Maryland", abbreviation: "MD" },
        { name: "Massachusetts", abbreviation: "MA" },
        { name: "Michigan", abbreviation: "MI" },
        { name: "Minnesota", abbreviation: "MN" },
        { name: "Mississippi", abbreviation: "MS" },
        { name: "Missouri", abbreviation: "MO" },
        { name: "Montana", abbreviation: "MT" },
        { name: "Nebraska", abbreviation: "NE" },
        { name: "Nevada", abbreviation: "NV" },
        { name: "New Hampshire", abbreviation: "NH" },
        { name: "New Jersey", abbreviation: "NJ" },
        { name: "New Mexico", abbreviation: "NM" },
        { name: "New York", abbreviation: "NY" },
        { name: "North Carolina", abbreviation: "NC" },
        { name: "North Dakota", abbreviation: "ND" },
        { name: "Ohio", abbreviation: "OH" },
        { name: "Oklahoma", abbreviation: "OK" },
        { name: "Oregon", abbreviation: "OR" },
        { name: "Pennsylvania", abbreviation: "PA" },
        { name: "Rhode Island", abbreviation: "RI" },
        { name: "South Carolina", abbreviation: "SC" },
        { name: "South Dakota", abbreviation: "SD" },
        { name: "Tennessee", abbreviation: "TN" },
        { name: "Texas", abbreviation: "TX" },
        { name: "Utah", abbreviation: "UT" },
        { name: "Vermont", abbreviation: "VT" },
        { name: "Virginia", abbreviation: "VA" },
        { name: "Washington", abbreviation: "WA" },
        { name: "West Virginia", abbreviation: "WV" },
        { name: "Wisconsin", abbreviation: "WI" },
        { name: "Wyoming", abbreviation: "WY" }
    ];

    useEffect(() => {

        setGiftSubscriptionPopup(props.step);
    }, [props.step]);

    const handlerNextStep = (step) => {
        setGiftSubscriptionPopup(step);
    };


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handlerClosePopup = () => {
        props.handlerClosePopup(false);
        setGiftSubscriptionPopup('first');

    }
    const handlerBackPopup = () => {
        setGiftSubscriptionPopup('first');
    }
    return (
        <>

            {giftSubscriptionPopup === 'first' ?
                <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className='GiftSubPopup'>
                    <Modal.Header closeButton>
                        <h3 className='gift-popup-center-heading'>Gift Remind</h3>
                    </Modal.Header>
                    <Modal.Body className="grid-example">

                        <Form>
                            <Row>
                                <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Homeowner First Name</Form.Label>
                                    <Form.Control type="text" placeholder="Mockk" />
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Homeowner Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Freddy" />
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Homeowner Email</Form.Label>
                                    <Form.Control type="text" placeholder="mockkfreddy@gmail.com" />
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Homeowner Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder="XXX-XXX-XXX" />
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Homeowner Address</Form.Label>
                                    <Form.Control type="text" placeholder="Please enter your address here" />
                                </Form.Group>


                                <Form.Group as={Col} md="4" className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Street</Form.Label>
                                    <Form.Control type="text" placeholder="street #123" />
                                </Form.Group>

                                <Form.Group as={Col} md="4" className="mb-3" controlId="exampleForm.ControlSelect1">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select>
                                        <option value="">Select a state</option>
                                        {states.map((state) => (
                                            <option key={state.abbreviation} value={state.abbreviation}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col} md="4" className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>ZIP Code</Form.Label>
                                    <Form.Control type="text" placeholder="32894" />
                                </Form.Group>

                            </Row>

                        </Form>
                    </Modal.Body>

                    <Modal.Footer>

                        <div className='giftPopup-modal-footer-btns'>

                            <button className='footer-button-cancel' onClick={handlerClosePopup} >Cancel</button>
                            <button type='submit' onClick={() => handlerNextStep('second')} className='proccedd-btn'>Proceed</button>
                        </div>


                    </Modal.Footer>


                </Modal>

                :
                <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className='GiftSubPopup'>
                    <Modal.Header closeButton>
                        <h3 className='gift-popup-center-heading'>Payment</h3>
                    </Modal.Header>
                    <Modal.Body className="grid-example">

                        <Form>
                            <Row>
                                <h2 className='payVia'>Pay Via:</h2>
                                <div className='d-flex giftpopup-radio-swtich'>


                                    <div className='radio-role-selection '>
                                        <input type='radio' id="card" checked={selectedOption === 'card'} onChange={handleOptionChange} value='card' className='role-radio-btn' name='role_choose' />
                                        <label className='giftpopup-radio-label' htmlFor='card'>Card</label>


                                    </div>
                                    <div className='radio-role-selection'>
                                        <input type='radio' id="wallet" checked={selectedOption === 'wallet'} onChange={handleOptionChange} value='wallet' className='role-radio-btn' name='role_choose' />
                                        <label className='giftpopup-radio-label' htmlFor='wallet'>REmind Wallet</label>
                                    </div>
                                </div>

                                {selectedOption === 'card' ? <>

                                    <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Card Holder Name</Form.Label>
                                        <Form.Control type="text" placeholder="Mockk" />
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Card Number</Form.Label>
                                        <Form.Control type="text" placeholder="Freddy" />
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Expiry Date</Form.Label>
                                        <Form.Control type="text" placeholder="MM/YY" />
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>CVV</Form.Label>
                                        <Form.Control type="number" maxLength="3" placeholder="123" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" className="mb-3 giftpopuo-wallet" controlId="exampleForm.ControlInput1">

                                        <div class="form-check check-me">
                                            <input class="form-check-input" type="checkbox" id="gridCheck" />
                                            <label class="form-check-label" for="gridCheck">
                                                Save card details
                                            </label>
                                        </div>
                                    </Form.Group>

                                </> : selectedOption === 'wallet' ?
                                    <div className='giftpopuo-wallet'>
                                        <div className='giftpopuo-wallet-label-AccountBal '>
                                            Account Balance
                                        </div >
                                        <div className='giftpopuo-wallet-AccountBal'>
                                            $3,672.59
                                        </div>
                                        <div className='giftpopuo-wallet-desc'>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi                                        </div>

                                        <div class="form-check check-me">
                                            <input class="form-check-input" type="checkbox" id="gridCheck" />
                                            <label class="form-check-label" for="gridCheck">
                                                I agree to the <Link to='#'>terms and conditions</Link>
                                            </label>
                                        </div>
                                    </div>



                                    : ''

                                }


                            </Row>

                        </Form>
                    </Modal.Body>

                    <Modal.Footer>

                        <div className='giftPopup-modal-footer-btns'>

                            <div className='d-flex giftPopup-modal-footer-twobtns'>
                                <button className='footer-button-cancel' onClick={handlerBackPopup} >Back</button>
                                <button className='footer-button-cancel' onClick={handlerClosePopup} >Cancel</button>
                            </div>

                            <button type='submit' onClick={() => handlerNextStep('last')} className='proccedd-btn'>{selectedOption === 'card' ? "Checkout" : 'Proceed'}</button>
                        </div>


                    </Modal.Footer>


                </Modal>
            }


        </>
    );
}

export default GiftNowPopup;
