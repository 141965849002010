import React from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg'
import editIcon from '../assets/icons/edit-icon.svg'
import deleteIcon from '../../user-dashboard/asstes/dash-icons/deleteIcon.svg'
import DataTable, { Alignment, Direction } from 'react-data-table-component';

const RecentlyReferred = () => {
    const columns = [
        {
            name: "Homeowner Name",
            selector: (row) => row.ClientName,
            sortable: false,
            reorder: true,
        },
        {
            name: "Sign Up Date",
            selector: (row) => row.ReferralCode,
            sortable: false,
            reorder: true,
        },
        {
            name: "Renewal Date",
            selector: (row) => row.RenewalDate,
            sortable: false,
            reorder: true,
        },
        {
            name: "Time Left to Claim Royalty",
            selector: (row) => row.RoyaltyClaimTimeLeft,
            sortable: false,
            reorder: true,
        },
        // {
        //     name: "Action",
        //     selector: (row) => row.Action,
        //     sortable: false,
        //     reorder: true,
        // }


    ];
    const data = [
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName  '>04/01/2023</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,
            RoyaltyClaimTimeLeft: <div className='fileName'>5 Months 2 Days Remaining</div>,
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span></span><span><img src={deleteIcon} /></span></div>

        },
         
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName  '>04/01/2023</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,
            RoyaltyClaimTimeLeft: <div className='fileName'>5 Months 2 Days Remaining</div>,
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span></span><span><img src={deleteIcon} /></span></div>

        },
         
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName  '>04/01/2023</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,
            RoyaltyClaimTimeLeft: <div className='fileName'>5 Months 2 Days Remaining</div>,
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span></span><span><img src={deleteIcon} /></span></div>

        },
         
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName  '>04/01/2023</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,
            RoyaltyClaimTimeLeft: <div className='fileName'>5 Months 2 Days Remaining</div>,
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span></span><span><img src={deleteIcon} /></span></div>

        },
         
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName  '>04/01/2023</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,
            RoyaltyClaimTimeLeft: <div className='fileName'>5 Months 2 Days Remaining</div>,
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span></span><span><img src={deleteIcon} /></span></div>

        },
         
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName  '>04/01/2023</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,
            RoyaltyClaimTimeLeft: <div className='fileName'>5 Months 2 Days Remaining</div>,
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span></span><span><img src={deleteIcon} /></span></div>

        },
         
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName  '>04/01/2023</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,
            RoyaltyClaimTimeLeft: <div className='fileName'>5 Months 2 Days Remaining</div>,
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span></span><span><img src={deleteIcon} /></span></div>

        },
         
        {
            id: 1,
            ClientName: <div className='fileName'>Jane Cooper</div>,
            ReferralCode: <div className='fileName  '>04/01/2023</div>,
            RenewalDate: <div className='fileName'>04/01/2023</div>,
            RoyaltyClaimTimeLeft: <div className='fileName'>5 Months 2 Days Remaining</div>,
            Action: <div className='actions'><span><img src={eyeIcon} /></span><span></span><span><img src={deleteIcon} /></span></div>

        },
         
       
        

    ]
    return (
        <div className="recentFilesTable recentEarning">
            <DataTable
                columns={columns}
                data={data}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={true}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={"300px"}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />

        </div>
    );
}

export default RecentlyReferred;
