import React from 'react';
import swtichIcon from '../assets/icons/refresh-cw.svg';
import { Link } from 'react-router-dom';
import dollarIcon from '../assets/icons/dollarIcon.svg'
import hashIcon from '../assets/icons/hashIcon.svg'
import giftIconDash from '../assets/icons/giftIconDash.svg'
import conservationRate from '../assets/icons/conservationRate.svg'
import RecentEarning from '../inc/recent-earning';
import { Form } from 'react-bootstrap';
import SelectDropDownYTD from '../inc/selectDropDownYTD';

const SellerOverview = () => {
    const homeEssentials = {
        items: [
            {
                icon: dollarIcon,
                heading: "Lifetime Earnings",
                subheading: "$25.2k",
                description: '',
                dropdown:false

            },
            {
                icon: dollarIcon,
                heading: "YTD Earnings",
                subheading: "$5.2k",
                description: '',
                dropdown:false
            },
            {
                icon: dollarIcon,
                heading: "Royalty Earnings",
                subheading: "$1.6k",
                description: '',
            },
            {
                icon: dollarIcon,
                heading: "Bonus Earnings",
                subheading: "$2.52k",
                description: '',
                dropdown:false
            },
            {
                icon: hashIcon,
                heading: "Number of Codes Redeemed",
                subheading: "56",
                description: '',
                dropdown:true
            },
            {
                icon: giftIconDash,
                heading: "Number of Subscriptions Gifted",
                subheading: "56",
                description: '',
                dropdown:true
            },
            {
                icon: conservationRate,
                heading: "Conversion Rate",
                subheading: "57%",
                description: false,
                dropdown:false
            },

        ],
    };
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">

                        <div className=" paddingRight0">

                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <h5 className="mb-2 text-titlecase mb-4">Overview</h5>
                                </div>
                               
                            </div>
                        </div>


                        <div className="">

                            <div className="row">
                                {homeEssentials.items.map((card) => {
                                    return (
                                        <div className="col-lg-3 item_each_home_cards">
                                            <div className="dashboard-card">
                                                <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                                    <div className=" justify-content-between align-items-center ">
                                                        <div className="icon-box-card">
                                                            <img src={card.icon} />  <p className="dashboard-subheading">
                                                                {card.subheading}
                                                                {
                                                       card.dropdown ? 
                                                       <div className='seller-card-dropdown'>

                                                           <SelectDropDownYTD />
                                                       </div>
                                                   
                                                        :''}
                                                            </p>
                                                        </div>
                                                        <p className="dashboard-heading">{card.heading}
                                                     
                                                        </p>
                                                        <p className="dashboard-description">
                                                            {card.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="col-lg-3 item_each_home_cards lastSellerItem">
                                    <div className="dashboard-card">
                                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                            <div className=" justify-content-between align-items-center ">
                                                <div className="icon-box-card">

                                                </div>
                                                <p className="dashboard-heading">Bonus Targets</p>
                                                <p className="dashboard-description">Explore Targets & Rewards</p>
                                                <Link to='/seller/remind-seller-program' className='dashboard-card-btn'>Explore Targets</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                            <div className=' mt-3'>
                                <div className="row mb-3">
                                    <div className="col-md-6 width40">
                                        <h2 className="card-heading">Recent Earnings</h2>
                                    </div>
                                    <div className="col-md-6 card-right-action width60">
                                        <div className='recent-earning-sortlble'>


                                            <label>Sort by</label>
                                            <Form.Select>
                                                <option>Alphabet</option>
                                                <option>Date</option>

                                            </Form.Select>
                                        </div>

                                    </div>
                                </div>
                                <RecentEarning />

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default SellerOverview;
