import React, { useState } from 'react';
import sellerMenuItem from './menu-item';
import { Link, useNavigate } from 'react-router-dom';
import giftIcon from '../assets/icons/gift.svg'
import logoSeller from '../assets/logo.png'
import mobiledownloaddesign from '../../user-dashboard/asstes/images/mobiledownloaddesign.svg'



const SellerSiderbar = ({ stateSide, handlerCloseSidebarFromClick }) => {
  const [activeItem, setActiveItem] = useState("");
  const navigate = useNavigate(); // Initialize navigate function

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
      setIsOpen(!isOpen);
  };

  const Popup = () => {
      return (
          <div className="popup">
              <div className="popup-content">


                  <img className='poppup-image' src={mobiledownloaddesign} />


                  <div className='logout-row'>
                      <div className='logout-button' onClick={togglePopup}> Close</div>
                  </div>

              </div>
          </div>
      );
  }

  const handleItemClick = (id) => {
    handlerCloseSidebarFromClick('close')
    document.body.classList.remove('no-scroll');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    if (id === 'logout') {
      // Clear the authentication token or any other user data
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('selectedProperty');
  
      // Redirect to the login page or another appropriate page
      navigate('/login');
    }else{
      setActiveItem(id);

    }

   };

  const NavMenu_2 = sellerMenuItem.children_2.map((item) => {
    const isActive = activeItem === item.id;
    const activeClassName = isActive ? "active" : "";

    return (
      <li className={`nav-item ${activeClassName}`} id={item.id} key={item.id}>
        <Link className="nav-link" to={item.url} onClick={() => handleItemClick(item.id)} >
          <span className="nav-icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
          <span className="menu-title">{item.title}</span>
        </Link>
      </li>
    );
  });

  const NavMenu_3 = sellerMenuItem.children_3.map((item) => {
    const isActive = activeItem === item.id;
    const activeClassName = isActive ? "active" : "";

    return (
      <li className={`nav-item ${activeClassName}`} id={item.id} key={item.id}>
        <Link className={ item.id === 'logout'? 'nav-link logoutIcon' : 'nav-link'  } to={item.url} onClick={() => handleItemClick(item.id)} >
          <span className="nav-icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
          <span className="menu-title">{item.title}</span>
        </Link>
      </li>
    );
  });


  return (
    <>
      <nav className={`sidebar sidebar-offcanvas ${stateSide === 'open' ? 'active' : ''}`}  id="sidebar">
        <div className="sidebar-inner">

          <ul className="seller nav choose-property">
            <div className="choose-property-img"><img src={logoSeller} alt="lo" /></div>
            <div className="choose-property-title">REmind Home</div>

          </ul>
          <div className='amount-widget'>
            <div className='account-balance-widget'>
              <div className='account-balance-title'>Account Balance</div>
              <div className='account-balance-amount'>$3,672.59</div>
              <div className='account-balance-btn'><img src={giftIcon} alt='GiftIcon' />Withdraw Balance</div>
            </div>
          </div>


          <div className='nav seller-program-date'>
            <span className='seller-program-date-title'>Affiliate Program Start Date:</span>
            <span className='seller-program-date-date'>23rd Feb 2024</span>
          </div>


          <div className="nav-divider"></div>
          <ul className="nav">{NavMenu_2}</ul>
          <div className="nav-divider"></div>

          <ul className="nav">{NavMenu_3}</ul>
          <div className="nav-divider"></div>

          <ul className="copyRightContent">
            <span>REmind, 2024</span>
          </ul>
        </div>
        <Link to='#' className="affliateProgramCard">
          <div className="affliateProgramCard-title">reminders in Hand!</div>
          <div className="affliateProgramCard-des">Install RemindHome Mobile App on your phone to never miss any reminder right on your mobile device.</div>
          <div className="affliateProgramCard-button" onClick={togglePopup}>Download Now</div>
        </Link>
      </nav>
      {isOpen &&
                <>
                    <div className='overlay-popup' onClick={togglePopup} ></div>
                    <Popup />
                </>
            }

    </>
  );
}

export default SellerSiderbar;
