import './App.css';
import CreateRoutes from './routes';
// boostrap
import 'bootstrap/dist/css/bootstrap.min.css';
import './user-dashboard/asstes/styles/responsiveness.css'
import './seller/assets/seller-responsiveness.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
 
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
             />
      
      <ToastContainer />

      <CreateRoutes />
    </div>
  );
}

export default App;
