import { axiosInstance } from "./axiosInstance";

export const getProperties = async () => {
    let res;
    try {
        res = await axiosInstance.get('/get-properties');

    } catch (error) {

    }
    return res ? res : "";


}

export const getPropertyById = async (id) => {
    let res;
    try {
        res = await axiosInstance.get(`/get-property/${id}`);

    } catch (error) {

    }
    return res ? res : "";


}

export const getStates = async () => {
    let res;
    try {
        res = await axiosInstance.get('/states');

    } catch (error) {

    }
    return res ? res : "";

}
export const getTaxonomyById = (taxonomyType, id) => {
    return axiosInstance.get(`/${taxonomyType}/${id}`);
};


export const getPropertyTypes = async () => {
    let res;
    try {
        res = await axiosInstance.get('/property-type');

    } catch (error) {

    }
    return res ? res : "";


}

export const getPropertyCategory = async () => {
    let res;
    try {
        res = await axiosInstance.get('/property-category');

    } catch (error) {

    }
    return res ? res : "";

}

export const addPropertyData = async (data) => {
    let res;
    try {
        res = await axiosInstance.post('/add-property', data);
    } catch (error) {
    }
    return res ? res : "";
}
export const updatePropertyData = async (data, id) => {
    let res;
    try {
        res = await axiosInstance.post(`/update-property/${id}`, data);
    } catch (error) {
    }
    return res ? res : "";
}
export const getSuggestedReminders = async () => {
    let res;
    try {
        res = await axiosInstance.get(`/get-suggested-reminders/`);
    } catch (error) {}
    return res ? res : "";
}
export const getCustomReminders = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

        res = await axiosInstance.get(`/get-custom-reminders/?property_id=${propertyData.id}`);
    } catch (error) {}
    return res ? res : "";
}

export const listOfFrequencies = async () => {

    let res;
    try {
        res = await axiosInstance.get(`/frequency-terms/`);
    } catch (error) {}
    return res ? res : "";
}

export const addNotificationData = async (data) => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    data['property_id'] = propertyData.id     
    try {
        res = await axiosInstance.post('/add-event', data);
    } catch (error) {
       res = error
    }
    return res ? res : "";
}

export const addCustomReminder = async (data) => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    data['property_id'] = propertyData.id     
    try {
        res = await axiosInstance.post('/add-reminder', data);
    } catch (error) {
       res = error
    }
    return res ? res : "";
}

export const fetchHomeEssentials = async () => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

     try {
        res = await axiosInstance.get(`/home-essentials?property_id=${propertyData.id}`);
    } catch (error) {
       res = error
    }
    return res ? res : "";
}
export const get_event_lists = async () => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

     try {
        res = await axiosInstance.get(`/event-lists/?property_id=${propertyData.id}`);
    } catch (error) {
       res = error
    }
    return res ? res : "";
}
