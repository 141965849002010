import React, { useState } from 'react';
import dubleCheckMark from '../../asstes/dash-icons/dubleCheckMark.svg';

const notifications = [
  {
    date: 'Today',
    title: 'New Reminder',
    content: 'Provide self-service help within your software and relevant to your users\' realtime context leading'
  },
  {
    date: 'Today',
    title: 'Another Reminder',
    content: 'Another notification for the same date'
  },
  {
    date: '05.02.2021',
    title: 'New Notification',
    content: 'This notification is for a different date'
  },
  {
    date: '05.02.2021',
    title: 'New Notification',
    content: 'This notification is for a different date'
  },
  {
    date: '07.02.2021',
    title: 'Warrenty',
    content: 'Provide self-service help within your software and relevant to your users\' realtime context leading '
  },
  // Add more notifications here
];

const NotificationPopup = ({handlerColseNotification}) => {
  const [notificationHide, setNotificationHide] = useState(false);

  const groupedNotifications = notifications.reduce((acc, notification) => {
    if (!acc[notification.date]) {
      acc[notification.date] = [];
    }
    acc[notification.date].push(notification);
    return acc;
  }, {});

  const handleNotificationClose = () => {
    setNotificationHide(true);
    handlerColseNotification(false)
  };

  return (
    <>
      {!notificationHide && (
        <>
        <div className="sidenav-overlay" onClick={handleNotificationClose} style={{left:'0px'}}></div>
        <div className='notification-popup'>
          <div className='notification-close' onClick={handleNotificationClose}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.0612 18.9387C21.343 19.2205 21.5013 19.6027 21.5013 20.0012C21.5013 20.3997 21.343 20.7819 21.0612 21.0637C20.7794 21.3455 20.3972 21.5038 19.9987 21.5038C19.6002 21.5038 19.218 21.3455 18.9362 21.0637L10.9999 13.125L3.0612 21.0612C2.7794 21.343 2.39721 21.5013 1.9987 21.5013C1.60018 21.5013 1.21799 21.343 0.936196 21.0612C0.654403 20.7794 0.496094 20.3972 0.496094 19.9987C0.496094 19.6002 0.654403 19.218 0.936196 18.9362L8.87495 11L0.938695 3.06122C0.656903 2.77943 0.498594 2.39724 0.498594 1.99872C0.498594 1.60021 0.656903 1.21802 0.938695 0.936225C1.22049 0.654432 1.60268 0.496123 2.0012 0.496123C2.39971 0.496123 2.7819 0.654432 3.0637 0.936225L10.9999 8.87498L18.9387 0.934975C19.2205 0.653182 19.6027 0.494873 20.0012 0.494873C20.3997 0.494873 20.7819 0.653182 21.0637 0.934975C21.3455 1.21677 21.5038 1.59896 21.5038 1.99747C21.5038 2.39599 21.3455 2.77818 21.0637 3.05998L13.1249 11L21.0612 18.9387Z" fill="#00487C" />
            </svg>
          </div>
          <div className='notification-header'>
            <div className='notification-title'>Notifications</div>
            <div className='notification-button'><img src={dubleCheckMark} alt="Mark all as read" />Mark all as read</div>
          </div>
          <div className='notification-list mt-3'>
            {Object.entries(groupedNotifications).map(([date, notifications]) => (
              <div key={date}>
                <div className='notifyItem-date'>{date}</div>
                {notifications.map((notification, index) => (
                  <div className='notification-item' key={index}>
                    <div className='notifyItem-title'>
                      <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2.5" cy="3" r="2.5" fill="#BE6060" />
                      </svg>
                      {notification.title}
                    </div>
                    <div className='notifyItem-content'>{notification.content}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        </>
      )}
    </>
  );
}

export default NotificationPopup;
