import React from 'react';
import DataTable, { Alignment, Direction } from "react-data-table-component";
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg'
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg'
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg'
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg'


const ListOfFIlesForEssemtialNav = () => {
   
    const columns = [
        {
            name: "File name",
            selector: (row) => row.filename,
            sortable: false,
            reorder: true,
        },
        {
            name: "",
            selector: (row) => row.action,
            sortable: false,
            reorder: true,
        },

    ];
    const data = [
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span></div>

        },
        {
            id:1,
            filename: <div className='fileName'><img src={pdfIcon} />document_name.pdf</div>,
            action:<div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span></div>

        },
    ]



    return (
        <div className="listoffilesForEssential">    
    <DataTable
      columns={columns}
      data={data}
      defaultSortFieldId={2}
      selectableRows={false}
      selectableRowsNoSelectAll={false}
      selectableRowsVisibleOnly={false}
      selectableRowsHighlight={false}
      selectableRowsSingle={false}
      expandableRows={false}
      expandOnRowClicked={false}
      expandOnRowDoubleClicked={false}
      expandableRowsHideExpander={false}
      pagination={false}
      highlightOnHover={true}
      striped={false}
      pointerOnHover={false}
      dense={false}
      persistTableHead={true}
      noHeader={false}
      fixedHeader={false}
      fixedHeaderScrollHeight={"300px"}
      progressPending={false}
      noTableHead={false}
      noContextMenu={false}
      direction={Direction.AUTO}
      subHeader={false}
      subHeaderAlign={Alignment.RIGHT}
      subHeaderWrap={true}
      responsive={true}
      disabled={false}
    />

        </div>

    );
}

export default ListOfFIlesForEssemtialNav;
