import React, { useState } from 'react';
import reminderNextbtn from '../../asstes/dash-icons/reminderNextbtn.svg';
import reminderPrvebtn from '../../asstes/dash-icons/reminderPrvebtn.svg';
import ReminderSideNavEdit from './reminderSideNavEdit';
import ReminderSideNavView from './reminderSideNavView';

const RemindersCalendar = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [sideNavWidth, setSideNavWidth] = useState('0px');
  const [sideNavWidthOverlay, setSideNavWidthOverlay] = useState('0%');
  const [continueChange, setContinueChange] = useState('viewStep');


  const handlerReminderSideNave = () => {
    setSideNavWidth('sidenavPopuWidth');
    setSideNavWidthOverlay('100%');
    setContinueChange('viewStep');

    document.body.classList.add('no-scroll');
  }

  const handlerCancelSideNav = () => {
    setSideNavWidth('sidenavPopuWidthHide');
    setSideNavWidthOverlay('0%');
    document.body.classList.remove('no-scroll');
  }

  const handlerChangeToEdit = () => {
    setContinueChange('editStep');
  }
  const currentDate = new Date();

  const events = [
    { id: 1, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'red', date: new Date(2024, 2, 24) }, // Mar 24
    { id: 2, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'green', date: new Date(2024, 2, 26) }, // Mar 25
    { id: 3, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'purple', date: new Date(2024, 2, 26) }, // Mar 26
    { id: 4, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'blue', date: new Date(2024, 2, 30) }, // Mar 30
    { id: 5, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'green', date: new Date(2024, 3, 1) }, // Apr 1
    { id: 6, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'camel', date: new Date(2024, 2, 31) }, // Apr 1
    { id: 7, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'red', date: new Date(2024, 3, 4) }, // Apr 1
    { id: 8, title: 'Home Essential', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse voluptate velit esse', type: 'purple', date: new Date(2024, 3, 10) }, // Apr 1
  ];

  const nextMonth = () => {
    setCurrentMonth(prevMonth => (prevMonth + 1) % 12);
    if (currentMonth === 11) setCurrentYear(prevYear => prevYear + 1);
  };

  const prevMonth = () => {
    setCurrentMonth(prevMonth => (prevMonth === 0 ? 11 : prevMonth - 1));
    if (currentMonth === 0) setCurrentYear(prevYear => prevYear - 1);
  };

  const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const getFirstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

  const generateCalendarDates = (month, year) => {
    const daysInCurrentMonth = getDaysInMonth(month, year);
    const firstDayOfMonth = getFirstDayOfMonth(month, year);

    const prevMonthDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevMonthYear = month === 0 ? year - 1 : year;
    const daysInPrevMonth = getDaysInMonth(prevMonth, prevMonthYear);

    const calendarDates = [];

    for (let i = prevMonthDays; i > 0; i--) {
      calendarDates.push(new Date(prevMonthYear, prevMonth, daysInPrevMonth - i + 1));
    }

    for (let i = 1; i <= daysInCurrentMonth; i++) {
      calendarDates.push(new Date(year, month, i));
    }

    const nextMonthDays = 42 - calendarDates.length;
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextMonthYear = month === 11 ? year + 1 : year;

    for (let i = 1; i <= nextMonthDays; i++) {
      calendarDates.push(new Date(nextMonthYear, nextMonth, i));
    }

    return calendarDates;
  };

  const currentMonthDates = generateCalendarDates(currentMonth, currentYear);

  const getEventsForDate = date => events.filter(event => event.date.toDateString() === date.toDateString());

  return (
    <div>
      <div className="reminder-navigation">
        <h2 className="card-heading">
          <span>{new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
          <img onClick={prevMonth} src={reminderPrvebtn} alt="Previous Month" />
          <img onClick={nextMonth} src={reminderNextbtn} alt="Next Month" />
        </h2>
      </div>
      <div className="parent-upcomingDateRow">
        <div className="row upcomingDateRow">
          {currentMonthDates.map((date, index) => (
            <div className={`dayBox ${date.toDateString() === currentDate.toDateString() ? 'current-day' : ''}`} key={index}>
              <div className="customDates">
                <span>{date.toLocaleString('default', { month: 'short', day: '2-digit' })}</span>
                <span className="day">{date.toLocaleString('en-us', { weekday: 'short' })}</span>
              </div>
              <div className="event-list">
                {getEventsForDate(date).map(event => (
                  <div onClick={() => handlerReminderSideNave(event)} className="parent-event-title" key={event.id}>
                    <div className={"event-calender-title type-" + event.type}>{event.title}</div>
                    <div className={"event-calender-des type-sub-" + event.type}>
                      {event.description}
                    </div>
                  </div>
                ))}
              </div>
              <div className="event_count">
                <a href="#">Events: {getEventsForDate(date).length}</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>
      <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
        {continueChange === 'viewStep' ? <ReminderSideNavView /> : <ReminderSideNavEdit /> } 
         
        <div className='side-footer'>
          <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>
          
          {continueChange === 'viewStep' ? <button className='footer-button-primary' onClick={handlerChangeToEdit}>Edit</button>:<button className='footer-button-primary' >Save</button>}
        </div>
      </div>
    </div>
  );
};

export default RemindersCalendar;
