import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import EyeSlash from '../../user-dashboard/asstes/dash-icons/auth/EyeSlash.svg'
import EyeSlashCross from '../../user-dashboard/asstes/dash-icons/auth/EyeSlashCross.svg'
import sellerimagesprograms from '../../user-dashboard/asstes/images/seller-images-programs.svg'
import { Link, useNavigate } from 'react-router-dom';
import logoutIcon from '../../user-dashboard/asstes/dash-icons/logoutIcon.svg';
import deactiveAccount from '../../user-dashboard/asstes/dash-icons/deactiveAccount.svg'
import mobiledownloaddesign from '../../user-dashboard/asstes/images/mobiledownloaddesign.svg'
import DeactivateAccountSideNav from '../../user-dashboard/components/inc/DeactivateAccountSideNav';
import { getPlanById, updateProfile, verifyPassword } from '../../axiosCalls/auth';
import { toast } from 'react-toastify';




const SellerUserSetting = () => {
    const [showPass, setShowPass] = useState(false);
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('suggets');
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [user, setUser] = useState(null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [planDetails, setPlanDetails] = useState(null);
    const [userStatus, setUserStatus] = useState('active');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate(); // Initialize navigate function

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
            // Check for plan ID and fetch plan details if available
            const userData = JSON.parse(storedUser);
            setUserStatus(userData?.meta_data?.status)
            if (userData.meta_data.plan_id) {
                fetchPlanDetails(userData.meta_data.plan_id);
            } else {
                calculateTrialPeriod(userData);
            }
        }
    }, []);



    const fetchPlanDetails = async (planId) => {
        try {
            const response = await getPlanById(planId);
            setPlanDetails(response.data);
        } catch (error) {
            toast.error("Failed to fetch plan details.");
        }
    };

    const calculateTrialPeriod = (userData) => {
        const signupMeta = userData.meta_data.signup_date;
        const trialEndMeta = userData.meta_data.trial_end_date;


        if (signupMeta && trialEndMeta) {
            const signupDate = new Date(signupMeta);
            const trialEndDate = new Date(trialEndMeta);


            setPlanDetails({
                title: "Trial Period",
                trial_period: Math.round((trialEndDate - signupDate) / (1000 * 60 * 60 * 24)),
                trial_end_date: trialEndDate,
            });
        }
    };




    const handleReactiveAccount = async () => {
        setLoading(true); // Start loading
        const updatedUser = {
            ...user,
            meta_data: {
                ...user.meta_data,
                status: 'active',
            },
        };

        setUser(updatedUser); // Update user state

        try {
            const response = await updateProfile({
                ...updatedUser,
                newPassword,
            });

            if (response && response.data) {
                localStorage.setItem('user', JSON.stringify(response.data));
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleDeactivateAccount = async () => {
        setLoading(true); // Start loading
        const updatedUser = {
            ...user,
            meta_data: {
                ...user.meta_data,
                status: 'inactive',
            },
        };

        setUser(updatedUser); // Update user state

        try {
            const response = await updateProfile({
                ...updatedUser,
                newPassword,
            });

            if (response && response.data) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('selectedProperty');
                navigate('/login');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handlerReminderSideNave = () => {

        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');
    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        setContinueChange('suggets')
        document.body.classList.remove('no-scroll');
    }

    const handlerShowPassword = () => {
        setShowPass(!showPass);
    };
    const [showPassOld, setShowPassOld] = useState(false);
    const handlerShowPasswordOld = () => {
        if (showPassOld) {
            setShowPassOld(false);

        } else {
            setShowPassOld(true);

        }
    }
    const handlerLogOut = () => {

        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('selectedProperty');
        // Redirect to the login page or another appropriate page
        navigate('/login');

    }
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUser((prevUser) => ({
            ...prevUser,
            meta_data: {
                ...prevUser.meta_data,
                [name]: value,
            },
        }));
    };
    if (!user) {
        return;
    }
    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        if (name === 'currentPassword') {
            setCurrentPassword(value);
        } else if (name === 'newPassword') {
            setNewPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };



    const handlerUserSubmit = async (e) => {
        e.preventDefault();
        if (!user.meta_data.first_name) {
            toast.error('Please fill  First Name field.');
            return;
        }
        if (!user.meta_data.last_name) {
            toast.error('Please fill Last Name field.');
            return;
        }


        // Check if the user wants to update the password
        if (newPassword || confirmPassword) {
            if (newPassword !== confirmPassword) {
                toast.error('New password and confirm password do not match.');
                return;
            }
            try {

                const data = {
                    currentPassword: currentPassword,
                    user_id: user.user_id
                }
                // Verify current password before updating
                const verifyResponse = await verifyPassword(data);
                if (!verifyResponse.data.success) {
                    toast.error('Current password is incorrect.');
                    return;
                }
            } catch (error) {
                toast.error('Failed to verify current password.');
                return;
            }
        }


        try {


            const response = await updateProfile({
                ...user,
                newPassword
            });
            if (response && response.data) {
                setUser(response.data);
                localStorage.setItem('user', JSON.stringify(response.data))
            }
        } catch (error) {

        }



    }

    const Popup = () => {
        return (
            <div className="popup">
                <div className="popup-content">


                    <img className='poppup-image' src={mobiledownloaddesign} />
                    <div className='logout-row'>
                        <div className='logout-button' onClick={togglePopup}> Close</div>
                    </div>

                </div>
            </div>
        );
    }


    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <h5 className="mb-2 text-titlecase mb-4">User settings</h5>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">
                            <div className="col-md-4 top-text-head">
                                <div className="reminder-top-button"> <button type='submit' className="filled-btn" onClick={handlerUserSubmit} >Save changes</button></div>
                            </div>


                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-6'>

                            <div className='user-setting-card mb-4'>
                                <div className='title-card'>
                                    General information
                                </div>
                                <Form>
                                    <Form.Group as={Col} md="12" className="mb-3" >
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="First Name" name="first_name" value={user.meta_data.first_name} onChange={handleInputChange} />
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" className="mb-3" >
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" name="last_name" value={user.meta_data.last_name} onChange={handleInputChange} />
                                    </Form.Group>

                                </Form>

                            </div>

                            <div className='user-setting-card mb-4 auth changePasswordCard'>
                                <div className='title-card'>
                                    Change password
                                </div>
                                <Form>
                                    <InputGroup>
                                        <label className='pass-label' htmlFor="exampleInputPassword1">Current password</label>
                                        <input type={showPassOld ? 'text' : 'password'} className="form-control  auth-password" id="exampleInputPassword1" name="currentPassword" value={currentPassword} onChange={handlePasswordChange} placeholder="" />

                                        <InputGroup.Text id="basic-addon1" onClick={handlerShowPasswordOld}>
                                            <img src={showPassOld ? EyeSlashCross : EyeSlash} /></InputGroup.Text>
                                    </InputGroup>

                                    <InputGroup >
                                        <label className='pass-label' htmlFor="exampleInputPassword1">New password</label>
                                        <input type={showPass ? 'text' : 'password'} className="form-control   auth-password" id="exampleInputPassword1" name="newPassword" value={newPassword} onChange={handlePasswordChange} placeholder="" />

                                        <InputGroup.Text id="basic-addon1" onClick={handlerShowPassword}>
                                            <img src={showPass ? EyeSlashCross : EyeSlash} /></InputGroup.Text>
                                    </InputGroup>
                                    <InputGroup >
                                        <label className='pass-label' htmlFor="exampleInputPassword1">Confirm password</label>
                                        <input type={showPass ? 'text' : 'password'} className="form-control   auth-password" id="exampleInputPassword1" name="confirmPassword" value={confirmPassword} onChange={handlePasswordChange} placeholder="" />

                                        <InputGroup.Text id="basic-addon1" onClick={handlerShowPassword}>
                                            <img src={showPass ? EyeSlashCross : EyeSlash} /></InputGroup.Text>
                                    </InputGroup>



                                </Form>

                            </div>



                        </div>
                        <div className='col-md-6'>

                            <div className='user-setting-seller-p mb-4'>

                                <div className='seller-p-body mb-4'>
                                    <div className='user-setting-seller-img'>

                                        <img src={sellerimagesprograms} />
                                    </div>
                                    <div className='user-setting-seller-text'>
                                        <span className='user-setting-seller-heading'>reminders in Hand!</span>
                                        <span className='user-setting-seller-sub-heading'>Install RemindHome Mobile App on your phone to never miss any reminder right on your mobile device.</span>
                                    </div>

                                </div>
                                <div className='user-setting-seller-read-more-btn' onClick={togglePopup}>
                                    <Link to="#">Read more</Link>
                                </div>

                            </div>

                        </div>
                        <div className='col-md-6'>
                            <div className='user-setting-card mb-4'>
                                <div className='title-card'>
                                    Other settings
                                    {userStatus === 'inactive' ?
                                        <div className={`logout-button reactive-account ${loading ? 'disabled' : ''}`} onClick={loading ? null : handleReactiveAccount}>
                                            {loading ? 'Reactivating...' : 'Reactivate'}
                                        </div>
                                        : ''
                                    }
                                </div>
                                <div className='logout-row'>
                                    <div className='logout-label'>Log out</div>
                                    <div className='logout-button' onClick={handlerLogOut}><img src={logoutIcon} />Log out</div>
                                </div>

                                {userStatus === 'active' ?
                                    <div className='logout-row deactiveAccountMobile'>
                                        <div className='logout-label'>Deactivate my account

                                            <p className='deaccount-notice mt-2'>
                                                <b>Warning!</b> This action cannot be cancelled !
                                            </p>
                                        </div>

                                        <div className='logout-button deaccount' onClick={handlerReminderSideNave}><img src={deactiveAccount} />Deactivate account</div>


                                    </div>
                                    : ''
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                <DeactivateAccountSideNav />


                <div className='side-footer'>

                    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

                    <button
                        className={`DeactivateAccount ${loading ? 'disabled' : ''}`}
                        onClick={loading ? null : handleDeactivateAccount}
                        disabled={loading}>
                        {loading ? 'Deactivating...' : 'Deactivate'}
                    </button>  

                </div>

            </div>




            {isOpen &&
                <>
                    <div className='overlay-popup' onClick={togglePopup} ></div>
                    <Popup />
                </>
            }
        </div>
    );
}

export default SellerUserSetting;
