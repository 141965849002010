import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import swtichIcon from '../assets/icons/refresh-cw.svg';
import GraphicRemindSellerProgram from '../inc/graphicRemindSellerProgram';



const REmindSellerProgram = () => {
    const giftedDetails = [
        {
            subscriptionGIft: 10,
            reward: '$50'
        },
        {
            subscriptionGIft: 25,
            reward: '$125'
        },
        {
            subscriptionGIft: 50,
            reward: '$250'
        },
        {
            subscriptionGIft: 100,
            reward: '$500'
        },
        {
            subscriptionGIft: 250,
            reward: '$1250'
        },
        {
            subscriptionGIft: 500,
            reward: '$2500'
        },
        {
            subscriptionGIft: 1000,
            reward: '$5000'
        },
        {
            subscriptionGIft: 5000,
            reward: '$12500'
        },
        {
            subscriptionGIft: 10000,
            reward: '$25000'
        },
        {
            subscriptionGIft: 25000,
            reward: '$50000'
        },
    ];
    // State to track the active button
    const [activeButton, setActiveButton] = useState('giftbtn'); // Assuming 'giftbtn' is initially active
    const [typeOfPage, setTypeOfPage] = useState('');

    // Function to handle button click
    const handleClick = (button) => {
        setActiveButton(button);
    };
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">
                        <div className=" paddingRight0">

                         
                        </div>
                        <div className="">

                            <div className="">

                                <div className='seller-payment-inner remindRewardProgram-main'>
                                    <div className='remindRewardProgram'>
                                        <div className='remindRewardProgram-heading mb-3'>
                                            Program Details
                                        </div>
                                        <div className='remindRewardProgram-sub-heading mb-3'>
                                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                        </div>
                                        <div className='remindRewardProgram-buttons mb-4'>
                                            <div
                                                className={`giftbtn ${activeButton === 'giftbtn' ? 'remindRewardProgramActive' : ''}`}
                                                onClick={() => handleClick('giftbtn')}
                                            >
                                                Gifting Program
                                            </div>

                                            {/* Referral Program button */}
                                            <div
                                                className={`referalbtn ${activeButton === 'referalbtn' ? 'remindRewardProgramActive' : ''}`}
                                                onClick={() => handleClick('referalbtn')}
                                            >
                                                Referral Program
                                            </div>
                                        </div>

                                        <div className='remindRewardProgram-table mb-4' >
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Subscriptions Gifted</th>
                                                        <th>Milestone Rewards</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {giftedDetails.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.subscriptionGIft}</td>
                                                                <td>{item.reward}</td>
                                                            </tr>
                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                        <div className='client-notes mb-4'>
                                            <div className='clientNotesHeading'>Gifting REmind to clients or anyone in your network</div>
                                            <ul>
                                                <li>REmind will offer the tool to affiliates (i.e. realtors) for a $75 annual subscription (25% discount from retail price).</li>
                                                <li>Affiliate pays $37.50 to gift a year 1 subscription.</li>
                                                <li>Affiliate recoups the $37.50 in year 2 if user referred renews.</li>
                                                <li>No free trial</li>
                                                <li>Affiliate receives 20% royalty on all subscriptions gifted starting in year 2.</li>
                                                <li>Affiliate receives cash bonuses upon achieving key milestones.</li>
                                            </ul>

                                        </div>

                                        <div className='checkProgress-btn'>
                                            <Link to="/seller/remind-seller-program-graph" >Check Progress</Link>
                                        </div>


                                    </div>

                                </div>

                                {/* <GraphicRemindSellerProgram /> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default REmindSellerProgram;
