import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const DeactivateAccountSideNav = () => {
    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>Deactivate Account</h3>
                <h5>Are you sure you want to deactivate your account? Please type <b>‘Deactivate’</b> to confirm your decision.</h5>
                <p className='deaccount-notice mt-2'>
                    <b>Warning!</b> This action cannot be cancelled !
                </p>
            </div>
            <div className='side-body'>
                <Form>
                    <Row>


                        {/* <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Confirm</Form.Label>
                            <Form.Control type="text" placeholder="For confirm type Deactivate" />
                        </Form.Group> */}

                    </Row>
                </Form>

            </div>
        </div>
    );
}

export default DeactivateAccountSideNav;
