import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ReferNowPopup = (props) => {

    const [giftSubscriptionPopup, setGiftSubscriptionPopup] = useState(props.step);
    const [selectedOption, setSelectedOption] = useState('card');



    useEffect(() => {

        setGiftSubscriptionPopup(props.step);
    }, [props.step]);

    const handlerNextStep = (step) => {
        setGiftSubscriptionPopup(step);
    };
    

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handlerClosePopup = () => {
        props.handlerClosePopupRefer(false);
        setGiftSubscriptionPopup('first');

    }

    const ReferralCode = [
        {
            id: 1,
            code: 'f45dsi8o0ssy'
        },
        {
            id: 2,
            code: 'Qaf45dsi8o0y'
        },
        {
            id: 3,
            code: 'Hf45dsi8o0y'
        },
        {
            id: 4,
            code: 'Pf45dsi8o0y'
        },
        {
            id: 5,
            code: 'Lf45dsi8o0y'
        },
    ]
    return (
        <>

            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className='GiftSubPopup'>
                <Modal.Header closeButton>
                    <h3 className='gift-popup-center-heading'>Refer Remind</h3>
                </Modal.Header>
                <Modal.Body className="grid-example">

                    <Form>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Homeowner First Name</Form.Label>
                                <Form.Control type="text" placeholder="Mockk" />
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Homeowner Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Freddy" />
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Homeowner Email</Form.Label>
                                <Form.Control type="text" placeholder="mockkfreddy@gmail.com" />
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlSelect1">
                                <Form.Label>Referral Code</Form.Label>
                                <Form.Select>
                                    <option value="">Select Referral Code</option>
                                    {ReferralCode.map((state) => (
                                        <option key={state.id} value={state.id}>
                                            {state.code}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>



                        </Row>

                    </Form>
                </Modal.Body>

                <Modal.Footer>

                    <div className='giftPopup-modal-footer-btns'>

                        <button className='footer-button-cancel' onClick={handlerClosePopup} >Cancel</button>
                        <button type='submit' onClick={() => handlerNextStep('second')} className='proccedd-btn'>Send</button>
                    </div>


                </Modal.Footer>


            </Modal>




        </>
    );
}

export default ReferNowPopup;
