import React, { useState } from "react";

import icon1 from "../asstes/dash-icons/sidebar/dashIcon1.svg";
import icon2 from "../asstes/dash-icons/sidebar/dashIcon2.svg";
import icon3 from "../asstes/dash-icons/sidebar/dashIcon3.svg";
import icon4 from "../asstes/dash-icons/sidebar/dashIcon4.svg";
import icon5 from "../asstes/dash-icons/sidebar/dashIcon5.svg";
import warningIcon from "../asstes/dash-icons/warningIcon.png";
import HomeEssentialsSideNav from "../components/inc/homeEssentialsSideNav";
import HomeEssentialsSideNavView from "../components/inc/homeEssentialsSideNavView";
import HomeEssentialsSideNavEdit from "../components/inc/homeEssentialsSideNavEdit";

const HomeEssentials = () => {
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('createStep');
    const [valueForView, setValueForView] = useState([]);

    const handlerReminderSideNave = () => {

        setContinueChange('createStep');
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');


    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');


    }
    const handlerChangeStep = () => {

        if (continueChange === 'suggets') {
            setContinueChange('EditStep')
        }

    }
    const handlerBackSideNav = () => {

        if (continueChange === 'createStep') {
            setContinueChange('suggets')
        }else{
            setContinueChange('suggets')

        }
    }
    const homeEssentials = {
        items: [
            {
                icon: icon1,
                heading: "Pretium",
                subheading: "Goodman G3300",
                warning: false,
            },
            {
                icon: icon2,
                heading: "Air Condicioner",
                subheading: "Goodman G3300",
                warning: true,
            },
            {
                icon: icon3,
                heading: "Ipsum",
                subheading: "Goodman G3300",
                warning: true,
            },
            {
                icon: icon4,
                heading: "Printer",
                subheading: "Goodman G3300",
                warning: false,
            },
            {
                icon: icon5,
                heading: "Mattis",
                subheading: "Goodman G3300",
                warning: false,
            },
            {
                icon: icon3,
                heading: "Ipsum",
                subheading: "Goodman G3300",
                warning: true,
            },
            {
                icon: icon1,
                heading: "Pretium",
                subheading: "Goodman G3300",
                warning: false,
            },
            {
                icon: icon2,
                heading: "Air Condicioner",
                subheading: "Goodman G3300",
                warning: true,
            }
        ],
    };
    function HandlerViewHomeEsstional(id, title) {

         const values = [{
            id: id,
            title: title
        }]
        setValueForView(values);
        setContinueChange('suggets');

        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');


    }
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <h5 className="mb-2 text-titlecase mb-4">Home Essentials</h5>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">

                            <div className=" top-text-head">

                                <div className="reminder-top-button">


                                    <button className="filled-btn" onClick={handlerReminderSideNave}>

                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673" stroke="white" strokeLinecap="round" />
                                        </svg>

                                        Add home essential</button>

                                </div>


                            </div>
                        </div>
                        <div className="row paddingRight0" >
                            {homeEssentials.items.map((card, index) => {
                                return (
                                    <div className="col-lg-3 col-md-6 col-sm-6 item_each_home_cards" id={index} key={index} onClick={(e) => HandlerViewHomeEsstional(index, card.heading)}>
                                        <div className="dashboard-card">
                                            <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                                <div className=" justify-content-between align-items-center ">
                                                    <div className="icon-box-card">
                                                        <img src={card.icon} alt={card.heading} />
                                                        {card.warning ? <div className="card-warining"><img alt="Warning" src={warningIcon} />warining</div> : ''}

                                                    </div>

                                                    <p className="dashboard-heading">{card.heading}</p>
                                                    <p className="dashboard-subheading">
                                                        {card.subheading}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </div>
            </div>

            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                {continueChange === 'suggets' ? <HomeEssentialsSideNavView propsValue={valueForView} /> : continueChange === 'createStep' ? <HomeEssentialsSideNav /> : <HomeEssentialsSideNavEdit />}


                <div className='side-footer'>

                    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

                    <button className='footer-button-primary ' onClick={handlerChangeStep}>{continueChange !== 'suggets' ? 'Save' : 'Edit'}</button>
                    {continueChange !== 'createStep' && continueChange !== 'suggets' ? <button className='footer-button-delete footer-button-back' >Delete</button> : ''}


                </div>

            </div>
        </div>
    );
}

export default HomeEssentials;
