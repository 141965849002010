import React, { useState } from 'react';
import '../../../asstes/styles/loginStyles.css';
import appleIcon from '../../../asstes/dash-icons/auth/appleIcon.svg';
import fbIcon from '../../../asstes/dash-icons/auth/fbIcon.svg';
import XIcon from '../../../asstes/dash-icons/auth/XIcon.svg';
import googleIcon from '../../../asstes/dash-icons/auth/googleIcon.svg';
import EyeSlash from '../../../asstes/dash-icons/auth/EyeSlash.svg';
import EyeSlashCross from '../../../asstes/dash-icons/auth/EyeSlashCross.svg';
import { Link, useNavigate } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { signUpApis } from '../../../../axiosCalls/auth';

const RegistrationAffliate = () => {
  const [showPass, setShowPass] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    occupation: '',
    agreeTerms: false,
    agreeReferral: false,
  });
  const navigate = useNavigate();

  const handlerShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validatePassword = (password) => {
    const minLength = 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    }
    if (!hasUpperCase) {
      return 'Password must contain at least one uppercase letter.';
    }
    if (!hasLowerCase) {
      return 'Password must contain at least one lowercase letter.';
    }
    if (!hasNumber) {
      return 'Password must contain at least one number.';
    }
    if (!hasSpecialChar) {
      return 'Password must contain at least one special character.';
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.firstName || !formData.lastName || !formData.email || !formData.password || !formData.confirmPassword || !formData.occupation) {
      toast.error('All fields are required.');
      return;
    }

    const passwordError = validatePassword(formData.password);
    if (passwordError) {
      toast.error(passwordError);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    if (!formData.agreeTerms) {
      toast.error('You must agree to the terms and conditions.');
      return;
    }

    if (!formData.agreeReferral) {
      toast.error('You must agree to the Referral Fee Agreement.');
      return;
    }

    const registrationData = {
      username: formData.email.split('@')[0],
      email: formData.email,
      password: formData.password,
      first_name: formData.firstName,
      last_name: formData.lastName,
      role: 'affiliate',
      status: 'active',
      meta_data: [
        {
          meta_name: 'occupation',
          meta_value: formData.occupation,
        },
      ],
    };

    try {
      const response = await signUpApis(registrationData);
      if(response){

        toast.success('Registration successful!');
        navigate('/login');
      }
    } catch (error) {
      console.error('Registration error:', error);
    }
  };

  return (
    <div className="">
      <div className="brand-logo">
        <h2 className='auth-title'>Register as <span className='subheading'>Affiliate</span></h2>
        <div className='auth-logos'>
          <img src={appleIcon} alt="appleIcon" />
          <img src={googleIcon} alt="googleIcon" />
          <img src={XIcon} alt="XIcon" />
          <img src={fbIcon} alt="fbIcon" />
        </div>
      </div>

      <h6 className="font-weight-light">Or sign up using email</h6>
      <form className="pt-3" onSubmit={handleSubmit}>
        <div className="row firstlast_row">
          <div className="col">
            <label className='pass-label' htmlFor="firstName">First name</label>
            <input type="text" className="form-control" placeholder="Michael" aria-label="First name" name="firstName" value={formData.firstName} onChange={handleInputChange} 
             autoComplete='off'
             required
            />
          </div>
          <div className="col">
            <label className='pass-label' htmlFor="lastName">Last name</label>
            <input type="text" className="form-control" placeholder="Jackson" aria-label="Last name" name="lastName" value={formData.lastName} onChange={handleInputChange}
             autoComplete='off'
             required
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" className="form-control form-control-lg" id="email" placeholder="mjack@gmail.com" name="email" value={formData.email} onChange={handleInputChange} 
           autoComplete='off'
           required
          />
        </div>

        <InputGroup>
          <label className='pass-label' htmlFor="password">Password</label>
          <input type={showPass ? 'text' : 'password'} className="form-control form-control-lg auth-password" id="password" name="password" value={formData.password} onChange={handleInputChange} 
           autoComplete='off'
           required
          />
          <InputGroup.Text id="basic-addon1" onClick={handlerShowPassword}>
            <img src={showPass ? EyeSlashCross : EyeSlash} alt="toggle visibility" />
          </InputGroup.Text>

        </InputGroup>



        <InputGroup>
          <label className='pass-label' htmlFor="confirmPassword">Confirm password</label>
          <input type={showPass ? 'text' : 'password'} className="form-control form-control-lg auth-password" id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} 
           autoComplete='off'
           required
          />
          <InputGroup.Text id="basic-addon1" onClick={handlerShowPassword}>
            <img src={showPass ? EyeSlashCross : EyeSlash} alt="toggle visibility" />
          </InputGroup.Text>
        </InputGroup>

        <div className="form-group">
          <label htmlFor="occupation">Occupation</label>
          <input type="text" className="form-control form-control-lg" id="occupation" name="occupation" placeholder="Your occupation" value={formData.occupation} onChange={handleInputChange} />
        </div>

        <div className="col-12">
          <div className="form-check check-me">
            <input className="form-check-input" type="checkbox" id="agreeTerms" name="agreeTerms" checked={formData.agreeTerms} onChange={handleInputChange} 
             required
            />
            <label className="form-check-label" htmlFor="agreeTerms">
              By clicking a ‘sign up’ button, I agree with <Link to='#'>REmind Terms and Conditions</Link>
            </label>
          </div>
        </div>

        <div className="col-12">
          <div className="form-check check-me">
            <input className="form-check-input" type="checkbox" id="agreeReferral" name="agreeReferral" checked={formData.agreeReferral} onChange={handleInputChange} required />
            <label className="form-check-label" htmlFor="agreeReferral">
              I have read <Link to='#'>Referral Fee Agreement</Link> and agree to its terms.
            </label>
          </div>
        </div>

        <div className="mt-3">
          <button type="submit" className='auth-success-btn'>Sign Up</button>
        </div>

        <div className="auth-footer">
          <Link className="auth-link" to="/login">I have an account</Link>
        </div>
      </form>
    </div>
  );
};

export default RegistrationAffliate;
