import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ListOfFIlesForEssemtialNav from './ListOfFIlesForEssemtialNav';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg'
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg'
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg'
import BroomIcon from '../../asstes/dash-icons/sidebar/Broom.svg'


const PurchasesServiceSideView = () => {
    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>Purchase Name</h3>


            </div>
            <div className='side-body homeEssentialSideNav'>
                <Form>
                    <Row style={{padding:'0px'}}>
                     
                     
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>
                                <div className='d-flex purchase-view-name'>
                                <Form.Label>Name</Form.Label>
                                <span className="services-badge  type-camel"  ><img src={BroomIcon} />Service</span>
                                </div>

                                <Form.Label>Purchase name</Form.Label>

                                <p className='text-paragraph'>Number</p>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Notes</Form.Label>
                                <p className='text-paragraph'>Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla. Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla.</p>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <div className='link-table-main'>
                               
                                <Form.Label>Vendor</Form.Label>
                                <p className='text-paragraph'>Jordane Enterprises</p>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <div className='link-table-main'>
                               
                                <Form.Label>Date</Form.Label>
                                <p className='text-paragraph'>01.02.2023</p>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <div className='link-table-main'>
                               
                                <Form.Label>Amount</Form.Label>
                                <p className='text-paragraph'>$59.99</p>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>
                                <Form.Label>Receipt</Form.Label>

                                <ListOfFIlesForEssemtialNav />

                            </div>

                        </Form.Group>
                   





                        <Form.Group md="12"  className="mb-3">
                            <div className='link-table-main'>

                                <Form.Label>Warranty</Form.Label>
                                <div className='warranty-section'>
                                    <span><b>Name: </b>Name of Warranty </span>
                                    <span><b>Number: </b>123456789 </span>
                                    <span><b>Dates: </b>02.01.2023 - 02.01.2028</span>
                                    <span><b>Notes: </b></span>
                                    <span><p>Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla. Et turpis sollicitudin tristique amet lacus lobortis tellus libero ac. Eget quis viverra ultrices a suscipit duis nulla.</p> </span>
                                    <span><b>File: </b></span>
                                    <span>
                                        <div className='warrantyFile'>
                                        <div className='text'><img src={pdfIcon} alt='pdfIcon' />document_name.pdf</div>
                                        <div className='actions'><span><img src={eyeIcon} /></span><span><img src={downloadIcon} /></span></div>
                                        </div>
                                        
                                        </span>


                                </div>
                                
                            </div>



                        </Form.Group>
                    </Row>
                </Form>


            </div>
        </div>
    );
}

export default PurchasesServiceSideView;
